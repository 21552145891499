import { flow, types } from 'mobx-state-tree';
import { getContractTariff, getSummariesByTariffIds } from '~/api/apiPab2c';
import createApiPathModel, {
  defaultModelState,
} from './models/createApiPathModel';
import { SERIES_CODE } from '~/constants/common';

const RequestsStateModel = types.model('State', {
  getSummariesByTariffIds: createApiPathModel(
    'GET /Summary/Summary/GetSummariesByTariffIds',
  ),
  getContractTariff: createApiPathModel('GET /Tax/Tax/GetContractTariff'),
});

const PriceInfoModel = types.model('PriceInfo', {
  /** Стоимость за день */
  daily: types.maybeNull(types.number),
  /** Стоимость за месяц */
  thirtyDays: types.maybeNull(types.number),
  annual: types.maybeNull(types.number),
});

const ChannelsInfoModel = types.model('', {
  channelCount: types.maybeNull(types.number),
});

const TagsModel = types.model('Tags', {
  /* Наименование тега */
  name: (types.string, ''),
});

const SummaryMobileInfo = types.model('SummaryMobileInfo', {
  baseMobilePhoneNumberCount: types.number,
  priceOnBaseMobilePhoneNumber: types.maybeNull(types.number),
  priceOnAdditionalMobilePhoneNumber: types.maybeNull(types.number),
  maxCountMobilePhoneNumberForBind: types.maybeNull(types.number),
});

const SummaryStore = types.model('Summary', {
  /** Наименование продукта */
  seriesName: (types.string, ''),
  /** Название маркетинг группы */
  marketingGroupName: (types.string, ''),
  marketingGroupCode: (types.string, ''),
  /** Информация о стоимости подключения и обслуживания на продукте */
  priceInfo: types.maybeNull(PriceInfoModel),
  /** Флаг годового продукта */
  isAnnual: (types.boolean, false),
  /** Базовая скорость доступа в интернет. Текст для отображения в блоке */
  speedBaseText: (types.string, ''),
  /** Информация о количестве каналов на продукте */
  channelsInfo: types.maybeNull(ChannelsInfoModel),
  /** Наименование продукта */
  seriesCode: (types.string, ''),
  /** Перечисление меток продукта */
  tags: types.array(TagsModel),
  /** Лимит рублей в день у трансформации */
  transformationLimit: types.maybeNull(types.number),
  tariffId: types.maybeNull(types.number),
  connectionMethod: (types.string, ''),
  /** Трансформер */
  isTransformer: (types.boolean, false),
  /** Число бесплатных номеров */
  phonesCount: types.maybeNull(types.number),
  mobileInfo: SummaryMobileInfo,
});

const ContractTariffModel = types.model('', {
  seriesCode: types.maybeNull(types.string),
  seriesId: types.maybeNull(types.number),
  tariffId: types.maybeNull(types.number),
});

export const SummaryDataStore = types
  .model('SummaryData', {
    requestsState: RequestsStateModel,
    summaryData: types.maybeNull(SummaryStore),
    contractTariff: types.maybeNull(ContractTariffModel),
  })
  .views((self) => ({
    get seriesName() {
      return self.summaryData?.seriesName;
    },
    get marketingGroupName() {
      return self.summaryData?.marketingGroupName;
    },
    get marketingGroupCode() {
      return self.summaryData?.marketingGroupCode;
    },
    get dailyPriceInfo() {
      return self.summaryData?.priceInfo?.daily;
    },
    get thirtyDaysPriceInfo() {
      return self.summaryData?.priceInfo?.thirtyDays;
    },
    get annualPriceInfo() {
      return self.summaryData?.priceInfo?.annual;
    },
    get isAnnual() {
      return self.summaryData?.isAnnual;
    },
    get speedBaseText() {
      return self.summaryData?.speedBaseText;
    },
    get channelCount() {
      return self.summaryData?.channelsInfo?.channelCount;
    },
    get tags() {
      return self.summaryData?.tags || [];
    },
    get transformationLimit() {
      return self.summaryData?.transformationLimit;
    },
    get connectionMethod() {
      return self.summaryData?.connectionMethod;
    },
    get isTransformer() {
      return self.summaryData?.isTransformer;
    },
    get phonesCount() {
      return self.summaryData?.phonesCount;
    },
    get seriesCode() {
      return self.contractTariff?.seriesCode;
    },
    get tariffId() {
      return self.contractTariff?.tariffId;
    },
    get isLoading() {
      return self.requestsState.getSummariesByTariffIds.isLoading;
    },
    get maxCountMobilePhoneNumberForBind() {
      return (
        self.summaryData?.mobileInfo?.maxCountMobilePhoneNumberForBind ?? 0
      );
    },
    get priceOnAdditionalMobilePhoneNumber() {
      return self.summaryData?.mobileInfo?.priceOnAdditionalMobilePhoneNumber;
    },
  }))
  .views((self) => ({
    get isMonoProduct() {
      return self.seriesCode === SERIES_CODE.MONO;
    },
  }))
  .actions((self) => ({
    getSummariesByTariffIds: flow(function* () {
      self.requestsState.getSummariesByTariffIds.reset();
      self.requestsState.getSummariesByTariffIds.setLoading();
      try {
        const contract = yield getContractTariff();
        self.contractTariff = contract;
        const res = yield getSummariesByTariffIds([contract.tariffId]);
        // eslint-disable-next-line prefer-destructuring
        self.summaryData = res[0];
        self.requestsState.getSummariesByTariffIds.setSuccess();
      } catch (e) {
        self.requestsState.getSummariesByTariffIds.setFail();
        throw e;
      }
    }),
    getContractTariff: flow(function* () {
      self.requestsState.getContractTariff.reset();
      self.requestsState.getContractTariff.setLoading();
      try {
        const res = yield getContractTariff();
        self.contractTariff = res;
        self.requestsState.getContractTariff.setSuccess();
        return res;
      } catch (e) {
        self.requestsState.getContractTariff.setFail();
        return null;
      }
    }),
    resetSummaryData: () => {
      self.summaryData = null;
      self.contractTariff = null;
    },
  }));

export const SummaryDataStoreInstance = SummaryDataStore.create({
  requestsState: {
    getSummariesByTariffIds: defaultModelState,
    getContractTariff: defaultModelState,
  },
  summaryData: null,
  contractTariff: null,
});
