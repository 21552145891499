import * as React from 'react';
import { Provider as MobxProvider } from 'mobx-react';
import { defaultTheme } from 'cordis-core-ui-planeta';
import { ThemeProvider } from '@emotion/react';
import GlobalStyles from '~/components/GlobalStyles';
import { RootStoreInstance } from '~/stores/RootStore';
import { CallToActionStore } from '~/components/Blocks/Templates/CallToAction/stores/CallToActionState/useCallToActionStateStore';
import { ChatleStateStore } from '../Blocks/Templates/Pab2c/Contract/ChatleWizard/store/useChatleStore';
import { TransformLimitExceededStoreInstance } from '../Blocks/Templates/Pab2c/Contract/TransformLimitExceeded/store/useTransformLimitExceededStore';
import { OperatingStateStore } from '../Blocks/Templates/Pab2c/ContractState/Components/OperatingStateWizard/store/useOperatingStateStore';
import { SettingsStore } from '../Blocks/Templates/Pab2c/Settings/store/useSettingsStore';
import { MobileSBPstore } from '../Blocks/Templates/Payment/MobileSBP/store/useMobileSBPStore';
import { VoiceStore } from '../Blocks/Templates/Pab2c/Voice/store/useVoiceStore';
import { VacationStore } from '../Blocks/Templates/Vacation/store/useVacationStore';
import { CallbackStore } from '../CallbackWizard/store/useCallbackStore';
import { ConnectionStore } from '../ConnectionWizard/store/useConnectionStore';
import { MyAppealsStore } from '../MyAppealsSidePage/store/useMyAppealsStore';
import { SoftlineStateStore } from '../Blocks/Templates/Softline/stores/useSoftlineStateStore';
import { catchMobxErrors } from '~/utils/MobxHelpers';
import { AnnualProductRenewalStoreInstance } from '../Blocks/Shared/AnnualProductRenewalWizard/store/useAnnualProductRenewalStore';
import { ContactsAndNotificationsStore } from '../Blocks/Shared/ContactsAndNotifications/store/useContactsAndNotificationsStore';
import { CreatePasswordStore } from '../Blocks/Shared/CreatePassword/store/useCreatePasswordStore';
import { DeviceDetailedCardStoreInstance } from '../Blocks/Shared/DeviceDetailedCard/store/useDeviceDetailedCardStore';
import { ListOfSbpBanksStore } from '../Blocks/Shared/ListOfSbpBanks/store/useListOfSbpBanksStore';
import { PinWizardStore } from '../Blocks/Shared/PinWizard/store/usePinWizardStore';
import { DeviceAfterAuthStore } from '../Blocks/Templates/Pab2c/Devices/stores/useDeviceAfterAuthStore';
import { ProductSwitcherWizardStoreInstance } from '../Blocks/Shared/ProductSwitcherWizard/store/useProductSwitcherWizardStore';

interface Props {
  children?: JSX.Element;
}

const MainProvider = ({ children }: Props): JSX.Element => {
  const [mounted, setMounted] = React.useState(false);

  React.useEffect(() => {
    setMounted(true);
    if (!process.env.IS_DEV) return;
    [
      RootStoreInstance,
      AnnualProductRenewalStoreInstance,
      ContactsAndNotificationsStore,
      CreatePasswordStore,
      DeviceDetailedCardStoreInstance,
      ListOfSbpBanksStore,
      PinWizardStore,
      ProductSwitcherWizardStoreInstance,
      CallToActionStore,
      ChatleStateStore,
      TransformLimitExceededStoreInstance,
      OperatingStateStore,
      DeviceAfterAuthStore,
      SettingsStore,
      VoiceStore,
      MobileSBPstore,
      SoftlineStateStore,
      VacationStore,
      CallbackStore,
      ConnectionStore,
      MyAppealsStore,
    ].forEach((item) => {
      catchMobxErrors(item);
    });
  }, []);

  const body = (
    <ThemeProvider theme={defaultTheme}>
      <GlobalStyles />
      <MobxProvider {...RootStoreInstance}>{children}</MobxProvider>
    </ThemeProvider>
  );

  // prevents ssr flash for mismatched dark mode
  if (!mounted) {
    return <div style={{ visibility: 'hidden' }}>Loading...</div>;
  }

  return body;
};

export default MainProvider;
