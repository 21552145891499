import { flow, Instance, types } from 'mobx-state-tree';
import { differenceInDays, isLastDayOfMonth, parseISO } from 'date-fns';
import { getTransformerEstimateDetails } from '~/api/apiPab2c';
import { TOTAL } from '../../constants';
import createApiPathModel from '~/stores/models/createApiPathModel';
import { toJS } from 'mobx';

const RequestsStateModel = types.model('State', {
  getTransformerEstimateDetails: createApiPathModel(
    'GET /Itv/Transformer/TransformerShowEstimateDetails',
  ),
});

const TransformLimitModel = types.model('', {
  amountPrice: types.number,
  amountPricePeriod: types.string,
  code: types.string,
  date: types.string,
  name: types.string,
  quantity: types.number,
});

const TransformLimitDetailsModel = types.model('', {
  startDate: types.string,
  endDate: types.string,
  items: types.array(TransformLimitModel),
});

const TransformLimitExceededStore = types
  .model('TransformLimitExceededModel', {
    requestsState: RequestsStateModel,
    /** Детализация превышения трансформера по датам */
    transformLimitDetailsData: types.maybeNull(
      types.array(TransformLimitDetailsModel),
    ),
    /** Итого за период */
    totalAmount: (types.number, 0),
  })
  .views((self) => ({
    get transformLimitDetails() {
      return toJS(self.transformLimitDetailsData);
    },
    get isLoading() {
      return self.requestsState.getTransformerEstimateDetails.isLoading;
    },
  }))
  .actions((self) => ({
    getTransformDetails: flow(function* (
      tariffId,
      startOfFinancialSettlementPeriod,
      endOfFinancialSettlementPeriod,
    ) {
      try {
        self.requestsState.getTransformerEstimateDetails.reset();
        self.requestsState.getTransformerEstimateDetails.setLoading();
        const res = yield getTransformerEstimateDetails(
          tariffId,
          startOfFinancialSettlementPeriod,
          endOfFinancialSettlementPeriod,
        );
        const details = res.reduce((acc, day, index) => {
          if (!acc.length)
            acc.push({
              startDate: day.date,
              endDate: null,
              items: day.items,
            });

          const itemsCurPeriod = acc[acc.length - 1].items
            .map((item) => item.name)
            .sort();
          const curItems = day.items
            .map((item) => {
              return item.name;
            })
            .sort();
          const hasSameNames = itemsCurPeriod.every(
            (element, i) => element === curItems[i],
          );
          const isLast = index === res.length - 1;

          if (itemsCurPeriod.length !== curItems.length || !hasSameNames) {
            acc[acc.length - 1].endDate = day.date;
            acc.push({
              startDate: day.date,
              endDate: null,
              items: day.items,
            });
          }

          if (isLast) {
            acc[acc.length - 1].endDate = day.date;
            return acc;
          }

          return acc;
        }, []);

        const total = details.reduce((acc, data, index) => {
          const periodTotal = data.items.find((i) => i.code === TOTAL);
          if (periodTotal.amountPrice !== 0) {
            let days =
              differenceInDays(
                parseISO(data.endDate),
                parseISO(data.startDate),
              ) + 1;
            const isLastInMonth = isLastDayOfMonth(parseISO(data.endDate));
            const isLastInArray = index === details.length - 1;
            if (days === 2 || (!isLastInMonth && !isLastInArray)) days--;
            const sum = periodTotal.amountPrice * days;
            return acc + sum;
          }
          return acc;
        }, 0);
        self.totalAmount = total;
        self.transformLimitDetailsData = details;
        self.requestsState.getTransformerEstimateDetails.setSuccess();
      } catch (e) {
        self.requestsState.getTransformerEstimateDetails.setFail();
        throw e;
      }
    }),
  }));

export default TransformLimitExceededStore;
export type ITransformLimitExceededStore = Instance<
  typeof TransformLimitExceededStore
>;
