import { types } from 'mobx-state-tree';
import {
  MOBILE_ACTIONS,
  STORAGE_TYPE,
  UNIT_TYPE,
} from '~/components/Blocks/Templates/Pab2c/Mobile/types';

const MobileStorageModel = types.model({
  /** Тип накопителя */
  mobileBalanceType: types.enumeration(Object.values(STORAGE_TYPE)),
  /** Единица измерения */
  unit: types.enumeration(Object.values(UNIT_TYPE)),
  /** Ежедневное начисление */
  accrualQuantity: types.maybeNull(types.number),
  /** Лимит начисления */
  maxQuantity: types.maybeNull(types.number),
  /** Стартовое значение накопителя */
  startQuantity: types.maybeNull(types.number),
  /** Текущее значение накопителя */
  currentQuantity: types.maybeNull(types.number),
  // Флаг переполненности накопителя.
  isOverflow: types.boolean,
  // Наименование последнего продукта, у которого накопитель был больше текущего.
  tariffNameOverflowStarted: types.maybeNull(types.string),
});

const MobileSubscriptionModel = types.model({
  /** Тип накопителя */
  mobileBalanceType: types.enumeration(Object.values(STORAGE_TYPE)),
  itemId: types.number,
  /** Единица измерения */
  unit: types.enumeration(Object.values(UNIT_TYPE)),
  fromDt: types.string,
  trimDt: types.maybeNull(types.string),
  /** Текущий баланс пакета */
  currentQuantity: types.maybeNull(types.number),
  /** Стартовый баланс пакета */
  startQuantity: types.maybeNull(types.number),
});

export const ContractMobilesModel = types.model({
  iccid: types.maybeNull(types.string),
  simCardId: types.maybeNull(types.number),
  msisdn: types.maybeNull(types.string),
  availableMobileActions: types.array(
    types.enumeration(Object.values(MOBILE_ACTIONS)),
  ),
  isEnabled: types.boolean,
  subscriberName: types.maybeNull(types.string),
  isInternationalRoamingEnabled: types.boolean,
  isActivated: types.boolean,
  mobilePhoneNumberId: types.maybeNull(types.number),
});

export const MobilePhoneInfoModel = types.model({
  simCardReplacePrice: types.number,
  simCardCourierDeliveryPrice: types.maybeNull(types.number),
  contractMobiles: types.maybeNull(types.array(ContractMobilesModel)),
});

export const MobileBalancesModel = types.model({
  storageMobileBalances: types.array(MobileStorageModel),
  itemSubscriptionMobileBalances: types.array(MobileSubscriptionModel),
});
