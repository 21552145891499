/** libraries */
import { createContext, useContext } from 'react';
/** components */
import DeviceAfterAuthModel, {
  IDeviceAfterAuthStore,
} from './DeviceAfterAuthStore';

export const DeviceAfterAuthStore = DeviceAfterAuthModel.create({
  deviceId: null,
  devicePurchaseState: null,
});

const StoreContext = createContext<IDeviceAfterAuthStore>(DeviceAfterAuthStore);

const useDeviceAfterAuthStore = (): IDeviceAfterAuthStore => {
  return useContext(StoreContext);
};

export default useDeviceAfterAuthStore;
