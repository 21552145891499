import { flow, Instance, types } from 'mobx-state-tree';
import { toJS } from 'mobx';
import RouterContext from 'next/router';
import { softlineSubscribe, softlineUnsubscribe } from '~/api/api';
import { SoftlineSubscriptionsModel } from '~/stores/models/SoftlineModel';
import createApiPathModel from '~/stores/models/createApiPathModel';
import { SOFT_SLUG } from '~/constants/common';
import { MY_SUBSCRIPTIONS_TAG } from '../constants';

const RequestsStateModel = types.model('State', {
  softlineSubscribe: createApiPathModel('POST /Softline/Softline/Bind'),
  softlineUnsubscribe: createApiPathModel('POST /Softline/Softline/Unbind'),
});

const SoftlineStateModel = types
  .model('SoftlineStateModel', {
    requestsState: RequestsStateModel,
    softlineDescriptionData: types.maybeNull(SoftlineSubscriptionsModel),
    isOpenDescription: (types.boolean, false),
    isOpenSubscribeWizard: (types.boolean, false),
    isOpenUnsubscribeWizard: (types.boolean, false),
    isOpenUnsubscribeWizardSuccess: (types.boolean, false),
    isOpenSubscribeWizardSuccess: (types.boolean, false),
    errorMessage: types.maybeNull(types.string),
    simIdFromBind: types.maybeNull(types.number),
  })
  .views((self) => ({
    get softlineDescription() {
      return toJS(self.softlineDescriptionData);
    },
    get isLoadingSoftlineState() {
      return self.requestsState.softlineSubscribe.isLoading;
    },
  }))
  .actions((self) => ({
    setIsOpenDescription: (isOpenDescription) => {
      self.isOpenDescription = isOpenDescription;
    },
    setIsOpenSubscribeWizard: (isOpenSubscribeWizard) => {
      self.isOpenSubscribeWizard = isOpenSubscribeWizard;
    },
    setIsOpenUnsubscribeWizard: (isOpenUnsubscribeWizard) => {
      self.errorMessage = null;
      self.isOpenUnsubscribeWizard = isOpenUnsubscribeWizard;
    },
    setIsOpenUnsubscribeWizardSuccess: (isOpenUnsubscribeWizardSuccess) => {
      self.isOpenUnsubscribeWizardSuccess = isOpenUnsubscribeWizardSuccess;
    },
    setIsOpenSubscribeWizardSuccess: (isOpenSubscribeWizardSuccess) => {
      self.isOpenSubscribeWizardSuccess = isOpenSubscribeWizardSuccess;
    },
    setErrorMessage: (errorMessage) => {
      self.errorMessage = errorMessage;
    },
    setSimIdFromBind: (simIdFromBind) => {
      self.simIdFromBind = simIdFromBind;
    },
    onCloseSubscribeSP: () => {
      self.isOpenSubscribeWizard = false;
      self.errorMessage = null;
    },
    /** Событие при клике на карточку */
    onSoftlineCardClick: (item) => {
      self.isOpenDescription = true;
      self.softlineDescriptionData = item;
    },
    /** Активация подписки */
    subscribe: flow(function* (getSubscriptionInfos, balanceUpdate) {
      self.requestsState.softlineSubscribe.reset();
      self.requestsState.softlineSubscribe.setLoading();
      try {
        self.errorMessage = null;
        const res = yield softlineSubscribe(
          self.softlineDescriptionData.metricId,
        );
        if (res) {
          self.simIdFromBind = res;
          setTimeout(balanceUpdate, 5000);
        }
        getSubscriptionInfos(res);
        self.isOpenSubscribeWizardSuccess = true;
        self.isOpenSubscribeWizard = false;
        self.isOpenDescription = false;
        self.requestsState.softlineSubscribe.setSuccess();
        RouterContext.push(
          `/${SOFT_SLUG}${`#${MY_SUBSCRIPTIONS_TAG.tag}`}`,
          `/${SOFT_SLUG}${`#${MY_SUBSCRIPTIONS_TAG.tag}`}`,
          {
            shallow: true,
          },
        );
      } catch (e) {
        self.errorMessage =
          'Ошибка подключения подписки. Пожалуйста, повторите попытку позже';
        self.requestsState.softlineSubscribe.setFail();
        throw e;
      }
    }),
    /** Деактивация подписки */
    unsubscribe: flow(function* (getSubscriptionInfos) {
      self.requestsState.softlineUnsubscribe.reset();
      self.requestsState.softlineUnsubscribe.setLoading();
      try {
        self.errorMessage = null;
        yield softlineUnsubscribe(self.softlineDescriptionData.simId);
        getSubscriptionInfos();
        self.isOpenUnsubscribeWizardSuccess = true;
        self.isOpenUnsubscribeWizard = false;
        self.isOpenDescription = false;
        self.requestsState.softlineUnsubscribe.setSuccess();
      } catch (e) {
        self.errorMessage =
          'Ошибка открепления подписки. Пожалуйста, повторите попытку позже';
        self.requestsState.softlineUnsubscribe.setFail();
        throw e;
      }
    }),
  }));
export default SoftlineStateModel;
export type ISoftlineStateStore = Instance<typeof SoftlineStateModel>;
