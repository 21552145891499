/** libraries */
import { types, Instance, cast, flow } from 'mobx-state-tree';
/** api */
import {
  changeTariff,
  checkTariffSwitch,
  tariffCheckSwitchAgreement,
} from '~/api/apiPab2c';
/** interfaces */
import { TariffProps } from '~/components/Blocks/Templates/ProductSwitcher/interfaces';
import { ResultConnect } from '~/components/Blocks/Templates/Summary/Summary.types';
/** constants */
import {
  DEFAULT_ERROR,
  DEFAULT_RESULT,
} from '~/components/Blocks/Shared/ProductSwitcherWizard/constants';
/** utils */
import { getProductChangeErrorTexts } from '~/components/Blocks/Templates/ProductSwitcher/utils';
import createApiPathModel from '~/stores/models/createApiPathModel';

const RequestsStateModel = types.model('State', {
  getAgreement: createApiPathModel('GET /Tax/Tax/GetTariffSwitchAgreement'),
  changeTariff: createApiPathModel('POST /Tax/Tax/SetTariff'),
});

const ResultPropsModel = types.model({
  isResult: types.boolean,
  isCorrect: types.boolean,
  text: types.maybe(types.string),
});

const ProductSwitcherWizardModel = types
  .model({
    requestsState: RequestsStateModel,
    /** Результат подключения/смены продукта */
    result: ResultPropsModel,
    /** Соглашение на смену продукта */
    agreement: types.string,
    productChangeError: types.string,
  })
  .views((self) => ({
    get isLoading() {
      return self.requestsState.changeTariff.isLoading;
    },
    get isLoadingAgreement() {
      return self.requestsState.getAgreement.isLoading;
    },
  }))
  .actions((self) => {
    return {
      setConnectResult: (result: ResultConnect) => {
        self.result = cast(result);
      },
      setProductChangeError: (error: string) => {
        self.productChangeError = error;
      },
      resetProductSwitcherWizardStore: () => {
        self.productChangeError = '';
        self.agreement = '';
        self.result = DEFAULT_RESULT;
      },
      getAgreement: flow(function* (
        tariff: TariffProps,
        tariffIdForSwitch: number,
        toggleChangeTariffWizardVisible: (tariff: TariffProps) => void,
        contractName: string,
      ) {
        self.requestsState.getAgreement.reset();
        self.requestsState.getAgreement.setLoading();
        try {
          const checkAgreement = yield tariffCheckSwitchAgreement(
            tariff.tariffId,
          );
          yield checkTariffSwitch(tariffIdForSwitch);
          self.agreement = checkAgreement;
          self.requestsState.getAgreement.setSuccess();
        } catch (e) {
          self.requestsState.getAgreement.setFail();
          const err = e?.errorMessage ? JSON.parse(e?.errorMessage) : null;
          self.productChangeError = err?.Type;
          const productChangeErrorTexts = yield getProductChangeErrorTexts(
            contractName,
            tariff.seriesName,
            tariff.seriesName,
            err.Extensions?.AgreementMessage,
          );
          self.agreement = productChangeErrorTexts[err?.Type] ?? DEFAULT_ERROR;
          throw e;
        } finally {
          toggleChangeTariffWizardVisible(tariff);
        }
      }),
      changeTariff: flow(function* (tariffId: number) {
        self.requestsState.changeTariff.reset();
        self.requestsState.changeTariff.setLoading();
        try {
          yield changeTariff(tariffId);
          self.result = {
            isResult: true,
            isCorrect: true,
            text: '',
          };
          self.requestsState.changeTariff.setSuccess();
        } catch (e) {
          self.requestsState.changeTariff.setFail();
          self.result = {
            isResult: true,
            isCorrect: false,
            text: DEFAULT_ERROR,
          };
          throw e;
        }
      }),
    };
  });

export default ProductSwitcherWizardModel;
export type IProductSwitcherWizardStore = Instance<
  typeof ProductSwitcherWizardModel
>;
