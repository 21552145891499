import { isAfter, parseISO } from 'date-fns';
import { MobileSubscription } from './types';

/** Сортировка пакетов */
export const getSortedSubscriptions = (
  subscription: MobileSubscription[],
): MobileSubscription[] => {
  if (subscription.length > 1) {
    const sorted = subscription.sort((a, b) =>
      isAfter(parseISO(a.trimDt), parseISO(b.trimDt)) ? 1 : -1,
    );
    return sorted;
  }
  return subscription;
};

/** Гб в Мб */
export const getGBtoMB = (num: number): number => {
  return Math.floor(num * 1000);
};
