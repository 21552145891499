import { flow, types } from 'mobx-state-tree';
import createApiPathModel, {
  defaultModelState,
} from './models/createApiPathModel';
import { getBanksWithSubscriptions } from '~/api/api';

const RequestsStateModel = types.model('State', {
  getSbpBanks: createApiPathModel('GET /Sbp/GetBanksWithSubscriptions'),
});

const SbpBankModel = types.model('SbpBankModel', {
  bankName: (types.string, ''),
  logo: (types.string, ''),
  schema: (types.string, ''),
});

export const SbpBanksStore = types
  .model('SbpBanks', {
    requestsState: RequestsStateModel,
    sbpBanks: types.array(SbpBankModel),
  })
  .views((self) => ({
    get isSbpBanksLoading() {
      return self.requestsState.getSbpBanks.isLoading;
    },
    get isSbpBanksError() {
      return self.requestsState.getSbpBanks.hasError;
    },
  }))
  .actions((self) => ({
    getSbpBanks: flow(function* () {
      self.requestsState.getSbpBanks.reset();
      self.requestsState.getSbpBanks.setLoading();
      try {
        const res = yield getBanksWithSubscriptions();
        self.sbpBanks = res;
        self.requestsState.getSbpBanks.setSuccess();
      } catch (e) {
        self.requestsState.getSbpBanks.setFail();
        throw e;
      }
    }),
    resetSbpBanks: () => {
      self.sbpBanks.clear();
    },
  }));

export const SbpBanksStoreInstance = SbpBanksStore.create({
  requestsState: {
    getSbpBanks: defaultModelState,
  },
  sbpBanks: [],
});
