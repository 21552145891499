import { flow, types } from 'mobx-state-tree';
import createApiPathModel, {
  defaultModelState,
} from './models/createApiPathModel';
import {
  getContractTrustedResume,
  startContractTrustedResume,
} from '~/api/api';
import { TRUSTED_RESUME_STATE } from '~/components/Blocks/Templates/DeferredPayment/DeferredPayment.types';

const RequestsStateModel = types.model('State', {
  getContractTrustedResume: createApiPathModel(
    'GET /Finances/ContractTrustedResume/Get',
  ),
  startContractTrustedResume: createApiPathModel(
    'POST /Finances/ContractTrustedResume/Start',
  ),
});

const DeferredPaymentDataModel = types.model('DeferredPaymentModel', {
  /** Подключена ли услуга в данный момент. */
  /** Возможность подключения услуги. */
  /** Продолжительность предоставления услуги в часах. */
  durationHours: types.maybeNull(types.number),
  durationSecondsLeft: types.maybeNull(types.number),
  trustedResumeState: types.maybeNull(
    types.enumeration(Object.values(TRUSTED_RESUME_STATE)),
  ),
});

export const DeferredPaymentModel = types
  .model('DeferredPayment', {
    requestsState: RequestsStateModel,
    deferredPayment: types.maybeNull(DeferredPaymentDataModel),
    /** Оставшиеся часы */
    hours: types.maybeNull(types.number),
    /** Оставшиеся минуты */
    minutes: types.maybeNull(types.number),
  })
  .views((self) => ({
    get isDeferredPayment() {
      return !!self.deferredPayment;
    },
    get isActivated() {
      return (
        self.deferredPayment?.trustedResumeState ===
        TRUSTED_RESUME_STATE.activated
      );
    },
    get isAvailable() {
      return (
        self.deferredPayment?.trustedResumeState ===
        TRUSTED_RESUME_STATE.available
      );
    },
    get isUsed() {
      return (
        self.deferredPayment?.trustedResumeState === TRUSTED_RESUME_STATE.used
      );
    },
    get durationHours() {
      return self.deferredPayment?.durationHours;
    },
    get durationSecondsLeft() {
      return self.deferredPayment?.durationSecondsLeft;
    },
    get isLoadingStartTrustedResume() {
      return self.requestsState.startContractTrustedResume.isLoading;
    },
    // Вывод оставшегося времени
    get timeOutput() {
      return `${self.hours ? `${self.hours} ч` : ''} ${
        self.minutes ? `${self.minutes} мин` : ''
      }`;
    },
  }))
  .actions((self) => ({
    getContractTrustedResume: flow(function* () {
      self.requestsState.getContractTrustedResume.reset();
      self.requestsState.getContractTrustedResume.setLoading();
      try {
        const res = yield getContractTrustedResume();
        self.deferredPayment = res;
        self.requestsState.getContractTrustedResume.setSuccess();
      } catch (e) {
        self.requestsState.getContractTrustedResume.setFail();
        throw e;
      }
    }),
  }))
  .actions((self) => ({
    duration: () => {
      const allMinutes = self.durationSecondsLeft / 60;
      self.hours = Math.trunc(allMinutes / 60);
      self.minutes = Math.trunc(allMinutes % 60);
    },
    activateDeferredPayment: flow(function* (isTemporaryTokenAuth) {
      if (isTemporaryTokenAuth) return;
      self.requestsState.startContractTrustedResume.reset();
      self.requestsState.startContractTrustedResume.setLoading();
      try {
        yield startContractTrustedResume();
        self.getContractTrustedResume();
        self.requestsState.startContractTrustedResume.setSuccess();
      } catch (e) {
        self.requestsState.startContractTrustedResume.setFail();
        throw e;
      }
    }),
    resetDeferredPayment: () => {
      self.deferredPayment = null;
    },
  }));

export const DeferredPaymentModelInstance = DeferredPaymentModel.create({
  requestsState: {
    getContractTrustedResume: defaultModelState,
    startContractTrustedResume: defaultModelState,
  },
  deferredPayment: null,
  hours: null,
  minutes: null,
});
