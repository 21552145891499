import { createContext, useContext } from 'react';
import mobileSBPModel, { IMobileSBPStore } from './MobileSBPModel';
import { defaultModelState } from '~/stores/models/createApiPathModel';
import { CHECK_URL_AMOUNT } from '../../constants';

export const MobileSBPstore = mobileSBPModel.create({
  requestsState: {
    getConvertedBanksList: defaultModelState,
  },
  selectedBank: null,
  deepLinkText: '',
  payload: '',
  isOpenSBPCards: false,
  waitingPage: false,
  convertedBanksList: [],
  convertedSBPBanksList: [],
  fetchAmount: CHECK_URL_AMOUNT,
  timerId: null,
  isZeroing: false,
  isUseSBPBanksList: false,
});

const MobileSBPStoreContext = createContext<IMobileSBPStore>(MobileSBPstore);

const useMobileSBPStore = (): IMobileSBPStore => {
  return useContext(MobileSBPStoreContext);
};

export default useMobileSBPStore;
