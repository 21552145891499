/** libraries */
import { createContext, useContext } from 'react';
/** components */
import contactsAndNotificationsModel, {
  IContactsAndNotificationsStore,
} from './ContactsAndNotificationsModel';
import { defaultModelState } from '~/stores/models/createApiPathModel';
import { DeletingContactStoreInstance } from './DeletingContactModel';
import { AddingContactStoreInstance } from './AddingContactModel';

export const ContactsAndNotificationsStore = contactsAndNotificationsModel.create(
  {
    deletingContactStore: DeletingContactStoreInstance,
    addingContactStore: AddingContactStoreInstance,
    requestsState: {
      getContactTypes: defaultModelState,
      getAllContacts: defaultModelState,
      getAllSubscriptions: defaultModelState,
    },
    isShowContactsAndNotifications: false,
    contactValueClasses: [],
    contactsData: [],
    allSubscriptions: [],
    seconds: 0,
    timerIsOn: false,
  },
);

const StoreContext = createContext<IContactsAndNotificationsStore>(
  ContactsAndNotificationsStore,
);

const useContactsAndNotificationsStore = (): IContactsAndNotificationsStore => {
  return useContext(StoreContext);
};

export default useContactsAndNotificationsStore;
