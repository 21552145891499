import { createContext, useContext } from 'react';
import AnnualProductRenewalStore, {
  IAnnualProductRenewalStore,
} from './AnnualProductRenewalModel';
import { INITIAL_RESULT } from '~/constants/common';

export const AnnualProductRenewalStoreInstance = AnnualProductRenewalStore.create(
  {
    isAnnualProductRenewal: false,
    result: INITIAL_RESULT,
    seriesName: '',
  },
);

const StoreContext = createContext<IAnnualProductRenewalStore>(
  AnnualProductRenewalStoreInstance,
);

const useAnnualProductRenewalStore = (): IAnnualProductRenewalStore => {
  return useContext(StoreContext);
};

export default useAnnualProductRenewalStore;
