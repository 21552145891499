import { cast, flow, types } from 'mobx-state-tree';
import createApiPathModel, {
  defaultModelState,
} from './models/createApiPathModel';
import { getTariffAllowed } from '~/api/apiPab2c';
import { toJS } from 'mobx';

const RequestsStateModel = types.model('State', {
  getTariffAllowed: createApiPathModel('GET /Tax/Tax/GetTariffAllowed'),
});

const TariffToModel = types.model('TariffToModel', {
  linePriceOff: types.maybeNull(types.number),
  seriesCode: types.maybeNull(types.string),
  tariffId: types.maybeNull(types.number),
  tariffMigrationRefuseReason: types.maybeNull(types.string),
});

const AllowedTariffModel = types.model('AllowedTariff', {
  price: types.maybeNull(types.number),
  tariffTo: types.maybeNull(TariffToModel),
});

export const AllowedTariffStore = types
  .model('AllowedTariffStore', {
    requestsState: RequestsStateModel,
    allowedTariffData: types.maybeNull(types.array(AllowedTariffModel)),
  })
  .views((self) => ({
    get allowedTariff() {
      return toJS(self.allowedTariffData);
    },
    get allowedTariffLength() {
      return self.allowedTariffData.length;
    },
  }))
  .actions((self) => ({
    getTariffAllowed: flow(function* () {
      self.requestsState.getTariffAllowed.reset();
      self.requestsState.getTariffAllowed.setLoading();
      try {
        const res = yield getTariffAllowed();
        self.allowedTariffData = res;
        self.requestsState.getTariffAllowed.setSuccess();
      } catch (e) {
        self.requestsState.getTariffAllowed.setFail();
        throw e;
      }
    }),
    resetAllowedTariff: () => {
      self.allowedTariffData = cast([]);
    },
  }));

export const AllowedTariffStoreInstance = AllowedTariffStore.create({
  requestsState: {
    getTariffAllowed: defaultModelState,
  },
  allowedTariffData: [],
});
