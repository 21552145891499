import { types } from 'mobx-state-tree';
import { CallToActionDeviceTypes } from '../../CallToAction.types';
import { ConnectionMethod } from '~/constants/common';
import { MobileInfoModel } from '~/stores/models/ConnectionTariffModel';

export const FixedPriceModel = types.model('FixedPrice', {
  /** Ссылка на изображение */
  imgLink: (types.string, ''),
  /** Новая цена */
  newPrice: types.maybeNull(types.number),
  /** Скидка */
  discount: types.maybeNull(types.number),
  /** Текст о длительности акции */
  dueDate: (types.string, ''),
});

const PromoInfoModel = types.model('PromoInfo', {
  /* Акционная стоимость рассрочки */
  annuity: types.maybeNull(types.number),
  /* Акционная стоимость первоначального взноса */
  downPayment: types.maybeNull(types.number),
  /* Дата окончания акции */
  finishDt: types.maybeNull(types.string),
  /* Количество оборудования, которое может быть приобретено по акции */
  maxPromoDevice: types.maybeNull(types.number),
  /* Наименование акции */
  name: (types.string, ''),
  /* Акционная стоимость подключения */
  price: types.maybeNull(types.number),
  /* Дата начала акции */
  startDt: (types.string, ''),
});

export const PriceInfoModel = types.model('PriceInfo', {
  /* За год */
  annual: types.maybeNull(types.number),
  /* Стоимость подключения к сети */
  connection: types.maybeNull(types.number),
  /* За день */
  daily: types.maybeNull(types.number),
  /* Стоимость подключения к продукту */
  migrationCostFirst: types.maybeNull(types.number),
  /* Авансовый платёж */
  prepayment: types.maybeNull(types.number),
  /* Блок с акциями */
  promoConnectionInfos: types.maybe(types.array(PromoInfoModel)),
  /* За тридцать дней */
  thirtyDays: types.maybeNull(types.number),
});

const TelevisionModel = types.model('Television', {
  /* Общее количество каналов доступных у провайдера */
  allChannelCount: types.maybe(types.number),
  /* Общее количество каналов на продукте */
  channelCount: types.maybe(types.number),
  /* Количество HD каналов */
  hdChannelCount: types.maybe(types.number),
  /* Количества UHD каналов */
  uhdChannelCount: types.maybe(types.number),
});

const TimeshiftModel = types.model('Timeshift', {
  /* Доступность на продукте */
  isAvailable: types.maybe(types.boolean),
  /* Стоимость */
  price: types.maybeNull(types.number),
});

const Device = types.model('', {
  /* Цена в рассрочку */
  annuity: types.maybeNull(types.number),
  /* Первоначальный взнос */
  downPayment: types.maybeNull(types.number),
  id: types.maybeNull(types.number),
  itemCode: (types.string, ''),
  /* Период рассрочки */
  leasePeriod: types.maybeNull(types.number),
  /* Наименование устройства */
  name: (types.string, ''),
  /* Единовременный платёж */
  ownershipOneTimeCharge: types.maybeNull(types.number),
  /* Цена в пользование */
  ownershipPriceOn: types.maybeNull(types.number),
  ownershipPriceOnPaymentPeriodCode: (types.string, ''),
  /* Цена в приобретение */
  price: types.maybeNull(types.number),
  /* Информация об акциях на приобретение устройств */
  promoPriceInfos: types.maybe(types.array(PromoInfoModel)),
  /* Тип устройства */
  type: types.enumeration(Object.values(CallToActionDeviceTypes)),
  warrantyForNew: types.maybeNull(types.number),
});

const InfoModel = types.model('Info', {
  devices: types.array(Device),
});

const TagModel = types.model('Tag', {
  /* Имя свойства */
  name: (types.string, ''),
  code: (types.string, ''),
  /* Номер сортировки */
  sort: types.maybeNull(types.number),
});

export const FieldModel = types.model('Field', {
  /* Информация о каналах */
  channelsInfo: types.maybe(TelevisionModel),
  /* Тип подключения */
  connectionMethod: types.enumeration(Object.values(ConnectionMethod)),
  /** Альтернативное наименование продукта */
  customHeader: types.maybe(types.string),
  /* Информация об оборудовании */
  devicesInfo: InfoModel,
  /* Продолжительность отложенного платежа (в часах) */
  distortionDuration: types.maybeNull(types.number),
  /* Флаг годового продукта */
  isAnnual: (types.boolean, false),
  /* Флаг Трансформера */
  isTransformer: (types.boolean, false),
  /* Код маркетинг категории */
  marketingGroupCode: (types.string, ''),
  /* Маркетинг категория */
  marketingGroupName: (types.string, ''),
  /* Описание продукта */
  marketingText: types.maybeNull(types.string),
  /* Стоимость продукта */
  priceInfo: types.maybeNull(PriceInfoModel),
  promoCodes: types.maybeNull(types.array(types.string)),
  /* Код серии продукта */
  seriesCode: types.maybe(types.string),
  seriesId: types.maybeNull(types.number),
  /* Название продукта */
  seriesName: (types.string, ''),
  speedBase: types.maybeNull(types.number),
  /* Скорость интернета */
  speedBaseText: (types.string, ''),
  /* Свойство продукта */
  tags: types.array(TagModel),
  tariffId: types.maybeNull(types.number),
  /* Информация об управлении просмотром */
  timeShiftInfo: types.maybeNull(TimeshiftModel),
  /** Данные для блока "Фиксируем цены на год" */
  fixedPrice: types.maybe(FixedPriceModel),
  mobileInfo: types.maybeNull(MobileInfoModel),
});

export const DefaultImgs = types.model('DefaultImgs', {
  type: (types.string, ''),
  imgLink: (types.string, ''),
});

export const SelectPurchaseTypesModel = types.model('SelectPurchaseTypes', {
  /* Наименование */
  label: (types.string, ''),
  /* Значение */
  value: (types.string, ''),
});
