import { createContext, useContext } from 'react';
import ListOfSbpBanksModel, {
  IListOfSbpBanksStore,
} from './ListOfSbpBanksModel';
import { defaultModelState } from '~/stores/models/createApiPathModel';

export const ListOfSbpBanksStore = ListOfSbpBanksModel.create({
  requestsState: {
    banksListImageConverter: defaultModelState,
  },
  isListOfSbpBanksOpen: false,
  convertedSbpBanksList: [],
  searchValue: '',
});

const ListOfSbpBanksStoreContext = createContext<IListOfSbpBanksStore>(
  ListOfSbpBanksStore,
);

const useListOfSbpBanksStore = (): IListOfSbpBanksStore => {
  return useContext(ListOfSbpBanksStoreContext);
};

export default useListOfSbpBanksStore;
