import { flow, types } from 'mobx-state-tree';
import createApiPathModel, {
  defaultModelState,
} from './models/createApiPathModel';
import { getTariffHistory } from '~/api/apiPab2c';

const RequestsStateModel = types.model('State', {
  getTariffHistory: createApiPathModel('GET /Tax/Tax/GetTariffHistory'),
});

const ProductHistoryModel = types.model('ProductHistory', {
  /** Код серии продукта */
  seriesCode: types.maybeNull(types.string),
  /** Дата подключения */
  date: (types.string, ''),
  /** Название тарифа */
  tariffName: (types.string, ''),
  /** Состояние */
  state: (types.string, ''),
});

export const ProductHistoryStore = types
  .model('ProductHistory', {
    requestsState: RequestsStateModel,
    productHistory: types.maybeNull(types.array(ProductHistoryModel)),
  })
  .actions((self) => ({
    getTariffHistory: flow(function* (fromDate: string, trimDate: string) {
      self.requestsState.getTariffHistory.reset();
      self.requestsState.getTariffHistory.setLoading();
      try {
        const res = yield getTariffHistory(fromDate, trimDate);
        self.productHistory = res;
        self.requestsState.getTariffHistory.setSuccess();
      } catch (e) {
        self.requestsState.getTariffHistory.setFail();
        throw e;
      }
    }),
    resetProductHistory: () => {
      self.productHistory = null;
    },
  }));

export const ProductHistoryStoreInstance = ProductHistoryStore.create({
  requestsState: {
    getTariffHistory: defaultModelState,
  },
  productHistory: null,
});
