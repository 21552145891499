import { defaultModelState } from '~/stores/models/createApiPathModel';
import TransformLimitExceededStore, {
  ITransformLimitExceededStore,
} from './TransformLimitExceededModel';
import { createContext, useContext } from 'react';

export const TransformLimitExceededStoreInstance = TransformLimitExceededStore.create(
  {
    requestsState: {
      getTransformerEstimateDetails: defaultModelState,
    },
    transformLimitDetailsData: [],
    totalAmount: 0,
  },
);

const TransformLimitExceededStoreContext = createContext<ITransformLimitExceededStore>(
  TransformLimitExceededStoreInstance,
);

const useTransformLimitExceededStore = (): ITransformLimitExceededStore => {
  return useContext(TransformLimitExceededStoreContext);
};

export default useTransformLimitExceededStore;
