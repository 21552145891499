/** libraries */
import { toJS } from 'mobx';
import { types, flow, applySnapshot } from 'mobx-state-tree';
/** api */
import {
  getPhoneData,
  getPhoneInfo,
  getPhoneSatelliteList,
} from '~/api/apiPab2c';
/** types */
import {
  AgreementModel,
  PhoneModel,
  SatelliteCodeModel,
  SatelliteModel,
} from './models/Pab2cVoiceModel';
import {
  Agreement,
  SatelliteCode,
} from '~/components/Blocks/Templates/Pab2c/Voice/interfaces';
/** utils */
import { getMinRentalTime } from '~/components/Blocks/Templates/Pab2c/Voice/utils';
import createApiPathModel, {
  defaultModelState,
} from '~/stores/models/createApiPathModel';

const RequestsStateModel = types.model('State', {
  getSatelliteList: createApiPathModel(
    'GET /Phone/Phone/GetPhoneSatelliteList',
  ),
  getPhoneData: createApiPathModel('GET /Phone/Phone/GetPhoneBindData'),
  getPhoneInfo: createApiPathModel('GET /Phone/Phone/GetPhoneInfo'),
});

export const Pab2cVoiceStore = types
  .model('Pab2cVoiceStore', {
    requestsState: RequestsStateModel,
    satelliteCode: SatelliteCodeModel,
    satelliteList: types.array(SatelliteModel),
    phoneList: types.array(PhoneModel),
    phoneData: AgreementModel,
  })
  .views((self) => ({
    /** Стоимость обслуживания одного номера */
    get maintainPrice() {
      return self.phoneData.rentalFee;
    },
    /** Соглашение для подключения номера телефона */
    get agreement() {
      return self.phoneData.agreement;
    },
    /** Стоимость получения номера */
    get rentalPrice() {
      return self.phoneData.rentalOnce;
    },
    /** Минимальный срок подключения номера */
    get minRentalTime() {
      if (self.phoneData.dateBind)
        return getMinRentalTime(
          self.phoneData.dateBind,
          self.phoneData.unbindBanTrimDate,
        );
      return null;
    },
    /** Все подключённые номера */
    get phones() {
      return toJS(self.phoneList);
    },
    get numberOfPhones() {
      return self.phoneList.length;
    },
    get satellite() {
      return toJS(self.satelliteList);
    },
    /** Стоимость обслуживания всех номеров на договоре */
    phonesMaintainPrice(freePhones: number) {
      if (freePhones >= self.phoneList.length || !freePhones) {
        return 0;
      }
      return self.phoneData.rentalFee * (self.phoneList.length - freePhones);
    },
    get isLoading() {
      return (
        self.requestsState.getSatelliteList.isLoading ||
        self.requestsState.getPhoneData.isLoading ||
        self.requestsState.getPhoneInfo.isLoading
      );
    },
    get isResult() {
      return (
        !self.requestsState.getSatelliteList.isLoading &&
        !self.requestsState.getPhoneData.isLoading &&
        !self.requestsState.getPhoneInfo.isLoading &&
        (self.requestsState.getSatelliteList.hasError ||
          self.requestsState.getPhoneData.hasError ||
          self.requestsState.getPhoneInfo.hasError ||
          self.requestsState.getSatelliteList.isSucceeded ||
          self.requestsState.getPhoneData.isSucceeded ||
          self.requestsState.getPhoneInfo.isSucceeded)
      );
    },
    get isCorrect() {
      return (
        self.requestsState.getPhoneData.isFetched ||
        !self.requestsState.getPhoneData.hasError
      );
    },
    get isFail() {
      return self.requestsState.getPhoneData.hasError;
    },
  }))
  .actions((self) => {
    return {
      getSatelliteCode: flow(function* (citySatellite: SatelliteCode) {
        self.requestsState.getSatelliteList.reset();
        self.requestsState.getSatelliteList.setLoading();
        try {
          const res = yield getPhoneSatelliteList();
          self.satelliteList = res;
          const code =
            res.find((item) => item.satellite === citySatellite)?.satellite ??
            res[0]?.satellite;
          self.requestsState.getSatelliteList.setSuccess();
          return code;
        } catch (e) {
          console.error('getSatelliteList', e);
          self.requestsState.getSatelliteList.setFail();
          return null;
        }
      }),
      setSatelliteCode: (code: 1 | -1 | 2) => {
        self.satelliteCode = code;
      },
      getPhoneData: flow(function* (code?: SatelliteCode) {
        self.requestsState.getPhoneData.reset();
        self.requestsState.getPhoneData.setLoading();
        try {
          const res: Agreement = yield getPhoneData(code ?? self.satelliteCode);
          applySnapshot(self.phoneData, res);
          self.requestsState.getPhoneData.setSuccess();
        } catch (e) {
          self.requestsState.getPhoneData.setFail();
          throw e;
        }
      }),
      /** Получает список подключённых номеров */
      getPhones: flow(function* () {
        self.requestsState.getPhoneInfo.reset();
        self.requestsState.getPhoneInfo.setLoading();
        try {
          const res = yield getPhoneInfo();
          self.phoneList = res;
          self.requestsState.getPhoneInfo.setSuccess();
        } catch (e) {
          self.requestsState.getPhoneInfo.setFail();
          throw e;
        }
      }),
      resetPab2cVoice: () => {
        applySnapshot(self, DEFAULT_STATE);
      },
    };
  })
  .actions((self) => {
    return {
      getVoiceState: flow(function* (citySatellite) {
        const satellite = yield self.getSatelliteCode(citySatellite);
        self.setSatelliteCode(satellite);
        yield Promise.all([self.getPhoneData(satellite), self.getPhones()]);
      }),
    };
  });

export const DEFAULT_STATE = {
  requestsState: {
    getSatelliteList: defaultModelState,
    getPhoneData: defaultModelState,
    getPhoneInfo: defaultModelState,
  },
  satelliteCode: null,
  phoneList: [],
  satelliteList: [],
  phoneData: {
    dateBind: '',
    dateUnbind: '',
    dateSuckEnd: '',
    unbindBanTrimDate: '',
    rentalFee: 0,
    rentalFeePaymentPeriodCode: '',
    rentalOnce: 0,
    agreement: '',
  },
};

export const Pab2cVoiceStoreInstance = Pab2cVoiceStore.create(DEFAULT_STATE);
