export enum STORAGE_TYPE {
  CALL = 'OutgoingCall',
  SMS = 'OutgoingSms',
  INTERNET = 'MobileInternet',
  ROAMING_INTERNET_ZONE_1 = 'InternationalRoamingMobileInternetZone1',
  ROAMING_INTERNET_ZONE_2 = 'InternationalRoamingMobileInternetZone2',
  ROAMING_INTERNET_ZONE_3 = 'InternationalRoamingMobileInternetZone3',
  ROAMING_INTERNET_ZONE_4 = 'InternationalRoamingMobileInternetZone4',
}

export enum UNIT_TYPE {
  Byte = 'Byte',
  MegaByte = 'MegaByte',
  GigaByte = 'GigaByte',
  Minute = 'Minute',
  Unit = 'Unit',
  Month = 'Month',
  Day = 'Day',
  Second = 'Second',
}

/** Информация о накопительных балансах */
export interface MobileStorage {
  mobileBalanceType: STORAGE_TYPE;
  unit: UNIT_TYPE;
  accrualQuantity: number;
  maxQuantity: number;
  startQuantity: number;
  currentQuantity: number;
  isOverflow: boolean;
}

/** Информация о балансах, связанных с экземплярами подписок */
export interface MobileSubscription {
  mobileBalanceType: STORAGE_TYPE;
  unit: UNIT_TYPE;
  itemId: number;
  fromDt: string;
  trimDt: string;
  currentQuantity: number;
  startQuantity: number;
}

/** Информация о балансах накопителя договора */
export interface MobileStorageBalances {
  storageMobileBalances: MobileStorage[];
  itemSubscriptionMobileBalances: MobileSubscription[];
}

/** Информация о подключенных мобильных номерах */
export interface MobilePhoneInfo {
  simCardReplacePrice: number;
  simCardCourierDeliveryPrice: number;
  contractMobiles: ContractMobiles[];
}

export interface ContractMobiles {
  iccid: string;
  simCardId: number;
  msisdn: string;
  availableMobileActions: string[];
  isEnabled: boolean;
  subscriberName: string;
  isInternationalRoamingEnabled: boolean;
  isActivated: boolean;
  mobilePhoneNumberId: number;
}

export interface ScaleProps {
  type: STORAGE_TYPE;
}

export enum DELIVERY_TYPE {
  /** Самовывоз из офисов */
  SELF_PICKUP = 'SelfPickup',
  /** Доставка курьером */
  COURIER = 'Courier',
}

export interface SimCardDeliveryInfo {
  deliveryType: DELIVERY_TYPE;
  deliveryAddress: 'string';
}

interface MobilePhoneNumberCategorie {
  name: string;
  price: number;
  id: number;
}

export interface MobilePhoneNumberCategories {
  mobilePhoneNumberCategories: MobilePhoneNumberCategorie[];
}

export interface AvailableMobilePhoneNumber {
  id: number;
  msisdn: string;
  categoryId: number;
  isFromUsed: boolean;
}

export interface AvailableMobilePhoneNumbers {
  availableMobilePhoneNumbers: AvailableMobilePhoneNumber[];
}

export enum EXEPTIONS {
  CLIENT_REQUEST_DEMAND_EXEPTION = 'ClientRequestDemandExeption',
}

export interface DocumentVerificationInfo {
  /** Признак, показывающий, что паспорт подтвержден на Госуслугах. */
  isDocumentVerified: boolean;
}

export enum MOBILE_ACTIONS {
  ReplaceSubscriberName = 'ReplaceSubscriberName',
  Unbind = 'Unbind',
  Block = 'Block',
  Unblock = 'Unblock',
  ReplaceNumber = 'ReplaceNumber',
  ReplaceSimCard = 'ReplaceSimCard',
  ActivateSimCard = 'ActivateSimCard',
  EnableInternationalRoaming = 'EnableInternationalRoaming',
  DisableInternationalRoaming = 'DisableInternationalRoaming',
}

interface AvailableItemSubscriptionProduct {
  mobileBalanceType: STORAGE_TYPE;
  unit: UNIT_TYPE;
  quantity: number;
}

/** Доступные пакеты для подключения к мобильному номеру */
export interface AvailableItemSubscription {
  name: string;
  price: number;
  itemId: number;
  trimDate?: string;
  itemSubscriptionProducts: AvailableItemSubscriptionProduct[];
}

export interface RoamingZone {
  name: string;
  countries: Country[];
  voiceTrafficRoamingPricesItems: RoamingPrice[];
  smsRoamingPricesItems: RoamingPrice[];
  packageRoamingPricesItems: RoamingPrice[];
}

interface Country {
  name: string;
  code: string;
}

export interface RoamingPrice {
  id: number;
  name: string;
  price: number;
}
