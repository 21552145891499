export enum NEW_CONTRACT_STATUS {
  /** Не внёс аванс */
  NoPrepayment = 'NoPrepayment',
  /**  Внёс недостаточно. */
  Insufficiently = 'Insufficiently',
  /** Внёс необходимый авансовый платёж и ожидает согласование времени подключения . */
  MountPlanning = 'MountPlanning',
  /** Время подключения согласовано. */
  Connecting = 'Connecting',
  /** Непонятно. */
  Unknown = 'Unknown',
}

export interface NewContractProps {
  // Сумма аванса
  moneyCharge: number;
  // Время запланированного подключения
  connectingDate?: string;
  // Статус нового абонента
  status: NEW_CONTRACT_STATUS;
  // Сколько денег необходимо внести абоненту
  leftToPay: number;
}

export enum VIEW_TYPES {
  /** Обслуживание не приостановлено */
  PAUSE,
  /** Успешная приостановка обслуживания */
  PAUSE_SUCCESS,
  /** Обслуживание приостановлено, отображается возобновление */
  RESUME,
  /** Успешное возобновление */
  RESUME_SUCCESS,
  /** Неуспешная приостановка обслуживания */
  PAUSE_ERROR,
  /** Неуспешное возобновление обслуживания */
  RESUME_ERROR,
}
