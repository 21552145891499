import { createContext, useContext } from 'react';
import { defaultModelState } from '~/stores/models/createApiPathModel';
import OperatingStateModel, {
  IOperatingStateStore,
} from './OperatingStateModel';

export const OperatingStateStore = OperatingStateModel.create({
  requestsState: {
    resumeService: defaultModelState,
    getSuspendingAgreement: defaultModelState,
    suspendService: defaultModelState,
  },
  isOperatingStateWizardShow: false,
  isResume: false,
  viewType: null,
  error: null,
  supensionAgreement: null,
  /** Ошибка проверки приостановки обслуживания */
  errorSuspendService: null,
});

const OperatingStateModelStoreContext = createContext<IOperatingStateStore>(
  OperatingStateStore,
);

const useOperatingStateModelStore = (): IOperatingStateStore => {
  return useContext(OperatingStateModelStoreContext);
};

export default useOperatingStateModelStore;
