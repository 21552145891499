/** libraries */
import { types } from 'mobx-state-tree';
/** constants */
import { DeviceCode } from '../../components/Blocks/Templates/Summary/Summary.types';
import { ConnectionMethod } from '../../constants/common';
import { defaultTheme } from 'cordis-core-ui-planeta';

export const FeatureModel = types.model({
  image: types.maybe(types.string),
  text: types.maybe(types.string),
  textColor: types.maybe(types.string),
  tagText: types.maybe(types.string),
  tagColor: types.maybe(types.string),
  backgroundColor: types.maybe(types.string),
});

export const PromoInfoPropsModel = types.model({
  name: types.string,
  price: types.number,
  startDt: types.string,
  finishDt: types.maybeNull(types.string),
  annuity: types.maybeNull(types.number),
  downPayment: types.maybeNull(types.number),
  maxPromoDevice: types.maybe(types.number),
});

const DeviceAbstractModel = types.model({
  name: types.string,
  discountPercent: types.number,
  isFree: types.boolean,
  price: types.number,
  ownershipPriceOn: types.maybeNull(types.number),
  promoPriceInfos: types.maybe(types.array(PromoInfoPropsModel)),
});

const DeviceModel = types.compose(
  DeviceAbstractModel,
  types.model({
    code: types.enumeration(Object.values(DeviceCode)),
  }),
);

const DevicesInfoModel = types.model({
  devices: types.array(DeviceModel),
});

const ChannelsInfoModel = types.model({
  channelCount: types.number,
  hdChannelCount: types.number,
  uhdChannelCount: types.number,
});

const PriceInfoModel = types.model({
  daily: types.maybe(types.number),
  thirtyDays: types.maybe(types.number),
  connection: types.number,
  annual: types.maybeNull(types.number),
  migrationCostFirst: types.number,
  promoConnectionInfos: types.maybeNull(types.array(PromoInfoPropsModel)),
  upgrade: types.maybe(types.number),
});

const TagModel = types.model({
  name: types.string,
  code: types.string,
  sort: types.maybe(types.number),
});

const TariffToPropsModel = types.model({
  linePriceOff: types.number,
  seriesCode: types.string,
  tariffId: types.number,
  tariffMigrationRefuseReason: types.maybeNull(types.string),
});

const AllowedTariffPropsModel = types.model({
  price: types.maybe(types.number),
  tariffTo: types.maybe(TariffToPropsModel),
});

export const ActionFieldsModel = types.model({
  header: types.maybe(types.string),
  text: types.maybe(types.string),
  background: types.maybe(types.string),
  icon: types.maybe(types.string),
  iconSize: types.maybe(
    types.model({ width: types.number, height: types.number }),
  ),
  secondIcon: types.maybe(types.string),
  newPrice: types.maybe(types.number),
  discount: types.maybe(types.number),
  color: types.maybe(types.enumeration(Object.values(defaultTheme.colors))),
});

const ActionTypeModel = types.model({
  action: types.maybe(ActionFieldsModel),
});

const StorageBalancesModel = types.model({
  storageType: types.maybeNull(types.string),
  unitType: types.maybeNull(types.string),
  accrualQuantity: types.maybeNull(types.number),
  maxQuantity: types.maybeNull(types.number),
  startQuantity: types.maybeNull(types.number),
});

export const MobileInfoModel = types.model({
  storageBalances: types.array(StorageBalancesModel),
  maxCountMobilePhoneNumberForBind: types.maybeNull(types.number),
});

export const SummaryFieldsModel = types.model({
  tariffId: types.number,
  seriesId: types.number,
  seriesName: types.string,
  seriesCode: types.string,
  connectionMethod: types.enumeration(Object.values(ConnectionMethod)),
  tags: types.maybeNull(types.array(TagModel)),
  priceInfo: PriceInfoModel,
  channelsInfo: ChannelsInfoModel,
  devicesInfo: DevicesInfoModel,
  speedBase: types.number,
  hasTransformerBro: types.boolean,
  speedBaseText: types.string,
  marketingText: types.maybeNull(types.string),
  marketingGroupCode: types.string,
  marketingGroupName: types.string,
  isTransformer: types.boolean,
  freeVoiceMinutes: types.maybeNull(types.number),
  phonesCount: types.number,
  callPriceMinuteMin: types.number,
  isAnnual: types.boolean,
  cities: types.maybeNull(types.array(types.number)),
  productFeedLink: types.maybe(types.string),
  transformationLimit: types.maybeNull(types.number),
  mobileInfo: types.maybeNull(MobileInfoModel),
  price: types.maybe(types.number),
  stripColor: types.maybe(types.string),
  features: types.maybe(types.array(FeatureModel)),
});

export const TariffPropsModel = types.compose(
  AllowedTariffPropsModel,
  SummaryFieldsModel,
  ActionTypeModel,
  types.model({
    sort: types.maybe(types.number),
  }),
);
