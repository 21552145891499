/** libraries */
import { types } from 'mobx-state-tree';
import { PromoInfoPropsModel } from './ConnectionTariffModel';

export const ImageSizeDataModel = types.model({
  width: types.number,
  height: types.number,
  type: types.string,
  mime: types.string,
  wUnits: types.string,
  hUnits: types.string,
  length: types.number,
  url: types.string,
  id: types.maybe(types.number),
});

export const ManualsPropsModel = types.model({
  name: types.string,
  link: types.string,
});

export const ColorModel = types.model({
  name: types.string,
  hex: types.string,
  image: types.string,
});

export const DevicesFieldsModel = types.model({
  annuity: types.maybeNull(types.number),
  archive: types.maybe(types.boolean),
  availabilityDt: types.string,
  connectionMethod: types.string,
  content: types.maybeNull(types.string),
  downPayment: types.maybeNull(types.number),
  gigabit: types.maybeNull(types.boolean),
  id: types.number,
  imageSize: types.maybeNull(ImageSizeDataModel),
  imageSizeInWizard: types.maybeNull(ImageSizeDataModel),
  images: types.maybe(types.string),
  isUsed: types.boolean,
  itemCode: types.string,
  leasePeriod: types.maybeNull(types.number),
  name: types.string,
  ownershipOneTimeCharge: types.maybeNull(types.number),
  ownershipPriceOn: types.maybeNull(types.number),
  ownershipPriceOnPaymentPeriodCode: types.string,
  price: types.number,
  promoPriceInfos: types.maybeNull(types.array(PromoInfoPropsModel)),
  sort: types.maybe(types.number),
  statusCode: types.string,
  type: types.string,
  typeName: types.string,
  vendorName: types.string,
  warrantyForNew: types.number,
  footer: types.maybe(types.string),
  manuals: types.maybe(types.array(ManualsPropsModel)),
  tag: types.maybe(types.string),
  colors: types.maybe(types.array(ColorModel)),
  isNotAvailable: types.maybe(types.boolean),
});
