/** libraries */
import { flow, types } from 'mobx-state-tree';
/** api */
import { changeIpAuthAllow, getIsIpAuthAllowed } from '~/api/apiPab2c';
/** utils */
import createApiPathModel, {
  defaultModelState,
} from '~/stores/models/createApiPathModel';
/** interfaces */
import { ResultPropsModel } from './models';
/** constants */
import { DEFAULT_ERROR } from '../constants';
import { PASSWORD_ERRORS } from '../SidePages/ChangePassword/constants';
import { SIDE_PAGES } from '~/components/AuthWizard/constants';
import { DEFAULT_RESULT } from '~/constants/common';

const RequestsStateModel = types.model('State', {
  getIsIpAuthAllowed: createApiPathModel(
    'GET /Security/PasswordRecovery/ChangePassword',
  ),
  changeIpAuthAllow: createApiPathModel(
    'GET /Security/PasswordRecovery/SetIpAuthAllow',
  ),
});

export const AllowAccessWithoutPasswordModel = types
  .model('AllowAccessWithoutPassword', {
    requestsState: RequestsStateModel,
    /** Показать сайдпейдж "Вход без пароля из дома" */
    isShowAllowAccessWizard: types.boolean,
    /** Флаг "Вход без пароля из дома" */
    isLoginWithoutPassword: types.boolean,
    password: types.string,
    passwordError: types.string,
    /** Результат изменения типа авторизации */
    result: ResultPropsModel,
  })
  .views((self) => ({
    /** Проверка на условия отключения кнопки смены типа авторизации */
    get isDisableButton() {
      if (!self.password.length) return true;
      return false;
    },
    get headerText() {
      if (self.result.isResult) return undefined;
      if (self.isLoginWithoutPassword)
        return 'Разрешить вход в раздел «Моя Планета» без пароля';
      return 'Запретить вход в раздел «Моя Планета» без пароля';
    },
    get isLoading() {
      return (
        self.requestsState.getIsIpAuthAllowed.isLoading ||
        self.requestsState.getIsIpAuthAllowed.isLoading
      );
    },
  }))
  .actions((self) => ({
    setIsShowAllowAccessWizard: (isShow: boolean) => {
      self.isShowAllowAccessWizard = isShow;
    },
    setIsLoginWithoutPassword: (isLogin: boolean) => {
      self.isLoginWithoutPassword = isLogin;
    },
    setPassword: (password: string) => {
      self.password = password;
    },
    setPasswordError: (error: string) => {
      self.passwordError = error;
    },
    /** Получает значение "Вход без пароля из дома" */
    getIsIpAuthAllowed: flow(function* () {
      self.requestsState.getIsIpAuthAllowed.reset();
      self.requestsState.getIsIpAuthAllowed.setLoading();
      try {
        const res = yield getIsIpAuthAllowed();
        self.isLoginWithoutPassword = res;
        self.requestsState.getIsIpAuthAllowed.setSuccess();
      } catch (e) {
        self.requestsState.getIsIpAuthAllowed.setFail();
        throw e;
      }
    }),
    /** Изменить значение "Вход без пароля из дома" */
    changeIpAuthAllow: flow(function* (setOpenSPAfterAuthorizationState) {
      self.requestsState.changeIpAuthAllow.reset();
      self.requestsState.changeIpAuthAllow.setLoading();
      self.passwordError = '';
      try {
        yield changeIpAuthAllow(self.password, self.isLoginWithoutPassword);
        self.result = { isResult: true, isCorrect: true };
        self.requestsState.changeIpAuthAllow.setSuccess();
        setOpenSPAfterAuthorizationState(null);
      } catch (e) {
        if (e.statusCode === 401) {
          setOpenSPAfterAuthorizationState(
            SIDE_PAGES.ACCESS_WITHOUT_PASSWORD_ACTION,
          );
          return;
        }
        setOpenSPAfterAuthorizationState(null);
        self.requestsState.changeIpAuthAllow.setFail();
        const error = JSON.parse(e.errorMessage);
        switch (error?.Type) {
          case PASSWORD_ERRORS.AUTH_COUNT:
            self.passwordError = `Неправильный пароль. По истечении попыток ввода пароля доступ к договору будет временно ограничен. Осталось попыток: ${error?.Extensions?.Remains}`;
            break;
          case PASSWORD_ERRORS.BLOCK:
            self.passwordError = `Доступ к договору ${error?.Extensions?.ContractName} заблокирован на ${error?.Extensions?.BlockMinutes} минут`;
            break;
          case PASSWORD_ERRORS.CONTRACT_NOT_SERVICED:
            self.passwordError = 'Договор не обслуживается';
            break;
          default:
            self.passwordError = DEFAULT_ERROR;
        }
        throw e;
      }
    }),
    resetState: () => {
      self.isShowAllowAccessWizard = false;
      if (
        !self.result.isResult ||
        (self.result.isResult && !self.result.isCorrect) ||
        self.passwordError
      ) {
        self.isLoginWithoutPassword = !self.isLoginWithoutPassword;
      }
      self.result = DEFAULT_RESULT;
    },
  }));

export const AllowAccessWithoutPasswordStoreInstance = AllowAccessWithoutPasswordModel.create(
  {
    requestsState: {
      getIsIpAuthAllowed: defaultModelState,
      changeIpAuthAllow: defaultModelState,
    },
    isShowAllowAccessWizard: false,
    isLoginWithoutPassword: false,
    password: '',
    passwordError: '',
    result: DEFAULT_RESULT,
  },
);
