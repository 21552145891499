import { createContext, useContext } from 'react';
import SoftlineStateModel, { ISoftlineStateStore } from './SoftlineStateModel';
import { defaultModelState } from '~/stores/models/createApiPathModel';

export const SoftlineStateStore = SoftlineStateModel.create({
  requestsState: {
    softlineSubscribe: defaultModelState,
    softlineUnsubscribe: defaultModelState,
  },
  softlineDescriptionData: null,
  isOpenDescription: false,
  isOpenSubscribeWizard: false,
  isOpenUnsubscribeWizard: false,
  isOpenUnsubscribeWizardSuccess: false,
  isOpenSubscribeWizardSuccess: false,
  errorMessage: null,
  simIdFromBind: null,
});

const SoftlineStateModelStoreContext = createContext<ISoftlineStateStore>(
  SoftlineStateStore,
);

const useSoftlineStateModelStore = (): ISoftlineStateStore => {
  return useContext(SoftlineStateModelStoreContext);
};

export default useSoftlineStateModelStore;
