import { cast, flow, types } from 'mobx-state-tree';
import { Dispatch, SetStateAction } from 'react';
import { toJS } from 'mobx';
import RouterContext from 'next/router';
import {
  authByIp,
  authByPassword,
  authByTemporaryToken,
  checkAuth,
  cookies,
  getContractInfo,
} from '~/api/api';
import {
  COOKIES_DEAD_TIME_IN_MINUTES,
  TOKEN,
  CONTRACT_PREFIX,
  CONTRACT_AUTH,
  DEFAULT_CONTRACT_AUTH,
  Pab2cSlugs,
  PAYMENT_SLUG,
} from '~/constants/common';
import {
  DEFAULT_ERROR,
  ERROR_MESSAGE,
  RESPONSE_STATUS,
} from '~/components/AuthWizard/constants';
import { AUTH_METHOD, ContractAuth } from '~/interfaces/ContractAuth.interface';
import createApiPathModel, {
  defaultModelState,
} from './models/createApiPathModel';
import { removeLeadingZero } from '~/utils/utils';
import { PAYMENT_QP } from '~/components/Blocks/Templates/PaymentResult/constants';

const RequestsStateModel = types.model('State', {
  getContractInfo: createApiPathModel('GET /Finances/Contract/GetContractInfo'),
  checkAuth: createApiPathModel('GET /Security/Auth/CheckAuth'),
  authByIp: createApiPathModel('POST /Security/Auth/AuthByIp'),
  authByTemporaryToken: createApiPathModel(
    'POST /Security/Auth/AuthByTemporaryToken',
  ),
});

const DotInfoModel = types.model('DotInfoModel', {
  cityId: types.maybeNull(types.number),
  streetName: types.maybeNull(types.string),
  buildingName: types.maybeNull(types.string),
  roomName: types.maybeNull(types.string),
});

const AuthModel = types.model('AuthStore', {
  /** Идентификатор договора */
  contractId: types.maybeNull(types.number),
  /** Имя/название клиента */
  clientName: (types.string, ''),
  /** Идентификатор договора */
  contractName: (types.string, ''),
  /** Идентификатор города */
  cityId: types.maybeNull(types.number),
  /** Баланс */
  balance: types.maybeNull(types.number),
  /** Адрес точки подключения */
  dotInfo: types.maybeNull(DotInfoModel),
  /** Токен */
  token: types.maybe(types.string),
  /** Способ аутентификации */
  authenticationMethod: types.maybeNull(
    types.enumeration(Object.values(AUTH_METHOD)),
  ),
  otherClientContractNames: types.array(types.string),
});

export const AuthStore = types
  .model('AuthStore', {
    requestsState: RequestsStateModel,
    makeAuthData: types.maybeNull(AuthModel),
    auth: AuthModel,
    /** Состояние меню */
    isMenuOpen: (types.boolean, false),
    isFirstAuth: (types.boolean, false),
    isOAuthData: types.map(types.boolean),
  })
  .views((self) => ({
    /** Флаг авторизации */
    get isAuth() {
      return self.auth?.contractId !== DEFAULT_CONTRACT_AUTH.contractId;
    },
    get isRemovedAuth() {
      const contractName = cookies.get(CONTRACT_AUTH)?.contractName;
      return (
        self.auth?.contractId === DEFAULT_CONTRACT_AUTH.contractId &&
        !contractName
      );
    },
    get isTemporaryTokenAuth() {
      return self.auth?.authenticationMethod === AUTH_METHOD.TEMPORARY_TOKEN;
    },
    get isOAuth() {
      return toJS(self.isOAuthData);
    },
    get isLoadingAuth() {
      return (
        self.requestsState.getContractInfo.isLoading ||
        self.requestsState.checkAuth.isLoading ||
        self.requestsState.authByIp.isLoading ||
        self.requestsState.authByTemporaryToken.isLoading
      );
    },
    get authData() {
      return toJS(self.auth);
    },
  }))
  .actions((self) => ({
    /** Удаляет из cookies и из контекста данные авторизации */
    removeAuth: (logout = false) => {
      const cookiesDomain =
        window.location.hostname !== 'localhost'
          ? process.env.COOKIE_DOMAIN
          : null;
      const contractName = cookies.get(CONTRACT_AUTH)?.contractName;
      cookies.remove(TOKEN, { path: '/', domain: cookiesDomain });
      cookies.remove(CONTRACT_AUTH, { path: '/', domain: cookiesDomain });
      if (contractName)
        window.sessionStorage.removeItem(removeLeadingZero(contractName));
      self.makeAuthData = null;
      self.auth = cast(DEFAULT_CONTRACT_AUTH);
      self.isFirstAuth = false;
      if (logout) self.isOAuthData.clear();
      if (RouterContext.router?.query?.[PAYMENT_QP.planetaOrderId] && logout) {
        RouterContext.router.replace(
          RouterContext.router.asPath.split('?')[0],
          undefined,
          {
            shallow: true,
          },
        );
      }
      if (RouterContext.router?.query?.slug !== undefined && logout) {
        switch (RouterContext.router?.query?.slug[0]) {
          case Pab2cSlugs.MAIN_SLUG:
            RouterContext.router.push('/', '/');
            break;
          case Pab2cSlugs.CHECK_PAYMENT:
            RouterContext.router.push(`/${PAYMENT_SLUG}`, `/${PAYMENT_SLUG}`);
            break;
          default:
        }
      }
    },
  }))
  .actions((self) => ({
    /**
     * Загружает данные договора
     * @param token - token пользователя
     * @param isUpdateAuth - флаг обновления cookie договора
     * @param {Dispatch<SetStateAction<boolean>>} setIsLoading Прелоадер кнопки "Войти" на SidePage авторизации
     * @param {Dispatch<SetStateAction<boolean>>} setIsVisible Флаг состояния сайдпейджа авторизации
     * @param {AUTH_METHOD} authMethod Способ авторизации
     */
    loadContractData: flow(function* (
      token?: string,
      isUpdateAuth?: boolean,
      setIsLoading?: Dispatch<SetStateAction<boolean>>,
      setIsVisible?: Dispatch<SetStateAction<boolean>>,
      authMethod?: AUTH_METHOD,
    ) {
      self.requestsState.getContractInfo.reset();
      self.requestsState.getContractInfo.setLoading();
      const cookiesDeadTime: Date = new Date();
      const cookiesDomain =
        window.location.hostname !== 'localhost'
          ? process.env.COOKIE_DOMAIN
          : null;

      const cookieOptions = {
        path: '/',
        expires: cookiesDeadTime,
        domain: cookiesDomain,
      };
      cookiesDeadTime.setMinutes(
        cookiesDeadTime.getMinutes() + COOKIES_DEAD_TIME_IN_MINUTES,
      );
      if (token) cookies.set(TOKEN, token, cookieOptions);
      try {
        const {
          clientName,
          contractId,
          contractName,
          cityId,
          balance,
          dotInfo,
          otherClientContractNames,
        } = yield getContractInfo();
        const contractFormedName = contractName.replace(CONTRACT_PREFIX, '');
        if (isUpdateAuth) cookies.remove(CONTRACT_AUTH, { path: '/' });
        if (setIsLoading) setIsLoading(false);
        if (setIsVisible) setIsVisible(false);

        cookies.set(
          CONTRACT_AUTH,
          {
            clientName,
            contractId,
            cityId,
            balance,
            contractName: contractFormedName,
            dotInfo,
            authenticationMethod: authMethod ?? self.auth.authenticationMethod,
            otherClientContractNames,
          },
          cookieOptions,
        );
        self.auth = {
          contractId,
          clientName,
          contractName: contractFormedName,
          cityId,
          balance,
          dotInfo,
          token: token ?? self.auth.token,
          authenticationMethod: authMethod ?? self.auth.authenticationMethod,
          otherClientContractNames,
        };
        self.requestsState.getContractInfo.setSuccess();
      } catch (e) {
        self.requestsState.getContractInfo.setFail();
        self.removeAuth();
        throw e;
      }
    }),
  }))
  .actions((self) => ({
    /**
     * Обновляет все данные договора
     */
    updateAuth: flow(function* (
      token?: string,
      isUpdateAuth?: boolean,
      setIsLoading?: Dispatch<SetStateAction<boolean>>,
      setIsVisible?: Dispatch<SetStateAction<boolean>>,
      authMethod?: AUTH_METHOD,
    ) {
      if (!self.auth.token) return;
      yield self.loadContractData(
        token,
        isUpdateAuth,
        setIsLoading,
        setIsVisible,
        authMethod,
      );
    }),
    /**
     * Сохраняет в cookies и в контекст данные авторизации
     * @param {ContractAuth} data
     * @param {Dispatch<SetStateAction<boolean>>} setIsLoading Прелоадер кнопки "Войти" на SidePage авторизации
     * @param {Dispatch<SetStateAction<boolean>>} setIsVisible Флаг состояния сайдпейджа авторизации
     */
    createAuth: flow(function* (
      data: ContractAuth,
      token?: string,
      isUpdateAuth?: boolean,
      setIsLoading?: Dispatch<SetStateAction<boolean>>,
      setIsVisible?: Dispatch<SetStateAction<boolean>>,
      authMethod?: AUTH_METHOD,
    ) {
      if (!data.token) {
        self.removeAuth();
        return;
      }
      yield self.loadContractData(
        token,
        isUpdateAuth,
        setIsLoading,
        setIsVisible,
        authMethod,
      );
    }),
  }))
  .actions((self) => ({
    setAuth: (auth) => {
      self.auth = auth;
    },
    setIsFirstAuth: (isFirstAuth) => {
      self.isFirstAuth = isFirstAuth;
    },
    setIsOAuth: (keyOAuth, isOAuth) => {
      self.isOAuthData.set(keyOAuth, isOAuth);
    },
    setIsMenuOpen: (isMenuOpen) => {
      self.isMenuOpen = isMenuOpen;
    },
    makeAuth: flow(function* (
      login,
      password,
      resetErrors,
      validation,
      setIsLoading,
      setServerError,
    ) {
      resetErrors();
      setIsLoading(true);
      try {
        const res = yield authByPassword(login, password);
        // обработка ошибок
        if (
          res.Status === RESPONSE_STATUS.status400 ||
          res.Status === RESPONSE_STATUS.status500
        ) {
          validation(res);
        }
        if (res && res.contractId) {
          self.makeAuthData = res;
        }
      } catch (errorData) {
        if (errorData.errorMessage) {
          const err = JSON.parse(errorData.errorMessage);
          validation(err);
          return;
        }
        setServerError(ERROR_MESSAGE[DEFAULT_ERROR]);
        setIsLoading(false);
        throw errorData;
      }
    }),
    /** Обновление баланса */
    balanceUpdate: flow(function* () {
      if (!self.auth.token) return;

      const { balance } = yield getContractInfo();

      const prevCookies = cookies.get(CONTRACT_AUTH);
      const cookiesDeadTime: Date = new Date();
      const cookiesDomain =
        window.location.hostname !== 'localhost'
          ? process.env.COOKIE_DOMAIN
          : null;

      const cookieOptions = {
        path: '/',
        expires: cookiesDeadTime,
        domain: cookiesDomain,
      };
      cookiesDeadTime.setMinutes(
        cookiesDeadTime.getMinutes() + COOKIES_DEAD_TIME_IN_MINUTES,
      );
      cookies.set(
        CONTRACT_AUTH,
        {
          ...prevCookies,
          balance,
        },
        cookieOptions,
      );
      self.auth = {
        ...self.auth,
        balance,
      };
    }),
    /**
     * Проверяет авторизационный token на backend'e
     */
    checkAuthAndSetContractData: flow(function* (
      currentToken?: string,
      token?: string,
      isUpdateAuth?: boolean,
      setIsLoading?: Dispatch<SetStateAction<boolean>>,
      setIsVisible?: Dispatch<SetStateAction<boolean>>,
      authMethod?: AUTH_METHOD,
    ) {
      if (currentToken) {
        self.requestsState.checkAuth.reset();
        self.requestsState.checkAuth.setLoading();
        try {
          const res = yield checkAuth();
          self.requestsState.checkAuth.setSuccess();
          if (res.status === 200) {
            yield self.updateAuth(
              token,
              isUpdateAuth,
              setIsLoading,
              setIsVisible,
              authMethod,
            );
            return;
          }
          self.removeAuth();
        } catch (e) {
          self.removeAuth();
          self.requestsState.checkAuth.setFail();
          throw e;
        }
      } else {
        self.removeAuth();
      }
    }),
    getAuthDataByIp: flow(function* (
      currentToken,
      isUpdateAuth?: boolean,
      setIsLoading?: Dispatch<SetStateAction<boolean>>,
      setIsVisible?: Dispatch<SetStateAction<boolean>>,
    ) {
      if (currentToken) return;
      try {
        self.requestsState.authByIp.reset();
        self.requestsState.authByIp.setLoading();
        const res = yield authByIp();
        if (res.contractId)
          yield self.createAuth(
            res,
            res.token,
            isUpdateAuth,
            setIsLoading,
            setIsVisible,
            res.authenticationMethod,
          );
        self.requestsState.authByIp.setSuccess();
      } catch (e) {
        self.requestsState.authByIp.setFail();
        throw e;
      }
    }),
    getAuthDataByTemporaryToken: flow(function* (
      contractName: string,
      temporaryToken: string,
      currentToken,
      isUpdateAuth?: boolean,
      setIsLoading?: Dispatch<SetStateAction<boolean>>,
      setIsVisible?: Dispatch<SetStateAction<boolean>>,
    ) {
      RouterContext.router.replace(`/${Pab2cSlugs.MAIN_SLUG}`, undefined, {
        shallow: true,
      });
      if (currentToken) self.removeAuth();
      try {
        self.requestsState.authByTemporaryToken.reset();
        self.requestsState.authByTemporaryToken.setLoading();
        const res = yield authByTemporaryToken(contractName, temporaryToken);
        if (res.contractId)
          yield self.createAuth(
            res,
            res.token,
            isUpdateAuth,
            setIsLoading,
            setIsVisible,
            res.authenticationMethod,
          );
        self.requestsState.authByTemporaryToken.setSuccess();
      } catch (e) {
        self.requestsState.authByTemporaryToken.setFail();
        throw e;
      }
    }),
  }));

export const initialAuthStoreState = {
  requestsState: {
    getContractInfo: defaultModelState,
    checkAuth: defaultModelState,
    authByIp: defaultModelState,
    authByTemporaryToken: defaultModelState,
  },
  makeAuthData: null,
  auth: DEFAULT_CONTRACT_AUTH,
  isMenuOpen: false,
  isFirstAuth: false,
  isOAuthData: {},
};

export const AuthStoreInstance = AuthStore.create(initialAuthStoreState);
