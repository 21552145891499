import { flow, Instance, types } from 'mobx-state-tree';
import { setReservedChatles } from '~/api/apiPab2c';
import { ERROR_DEFAULT } from '../constants';
import createApiPathModel from '~/stores/models/createApiPathModel';

const RequestsStateModel = types.model('State', {
  setReservedChatles: createApiPathModel(
    'POST /Chatle/Chatle/SetReservedChatles',
  ),
});

const ReserveChatleModel = types.model('', {
  status: types.maybeNull(types.number),
});

const ChatleStateModel = types
  .model('ChatleModel', {
    requestsState: RequestsStateModel,
    serverError: (types.string, ''),
    chatleAmount: (types.string, ''),
    maxChatleAmount: types.maybeNull(types.number),
    errorChatleAmount: (types.boolean, false),
    isChatleLoading: (types.boolean, false),
    isChatleCompleted: (types.boolean, false),
    reserveChatleData: types.maybeNull(ReserveChatleModel),
    isChatleVisible: (types.boolean, false),
  })
  .views((self) => ({
    get hasAmountError() {
      return (
        +self.chatleAmount <= 0 || +self.chatleAmount > self.maxChatleAmount
      );
    },
  }))
  .actions((self) => ({
    setServerError: (serverError) => {
      self.serverError = serverError;
    },
    setChatleAmount: (chatleAmount) => {
      self.chatleAmount = chatleAmount;
    },
    setMaxChatleAmount: (maxChatleAmount) => {
      self.maxChatleAmount = maxChatleAmount;
    },
    setErrorChatleAmount: (errorChatleAmount) => {
      self.errorChatleAmount = errorChatleAmount;
    },
    setIsChatleLoading: (isChatleLoading) => {
      self.isChatleLoading = isChatleLoading;
    },
    setIsChatleCompleted: (isChatleCompleted) => {
      self.isChatleCompleted = isChatleCompleted;
    },
    setReserveChatleData: (reserveChatleData) => {
      self.reserveChatleData = reserveChatleData;
    },
    setIsChatleVisible: (isChatleVisible) => {
      self.isChatleVisible = isChatleVisible;
    },
    /** Открывает/закрывает сайд пейдж */
    toggleChatleVisible: (): void => {
      self.isChatleVisible = !self.isChatleVisible;
    },
    /** Обработка закрытия виджета в SidePage */
    onCloseClickSidePageChatle: (): void => {
      self.isChatleVisible = false;
    },
    validateChatleAmount: (): void => {
      self.errorChatleAmount = self.hasAmountError;
    },
    reserveChatles: flow(function* (getBalance) {
      if (self.errorChatleAmount) self.errorChatleAmount = null;
      if (self.serverError) self.serverError = null;
      self.isChatleLoading = true;
      try {
        const res = yield setReservedChatles(+self.chatleAmount);
        if (res.status && res.status === 200) {
          self.reserveChatleData = res;
          self.isChatleCompleted = true;
          getBalance();
        }
      } catch (errorData) {
        const err = errorData.errorMessage
          ? JSON.parse(errorData.errorMessage)
          : ERROR_DEFAULT;
        self.serverError = err;
        throw errorData;
      } finally {
        self.isChatleLoading = false;
      }
    }),
  }));

export default ChatleStateModel;
export type IChatleStateStore = Instance<typeof ChatleStateModel>;
