import { createContext, useContext } from 'react';
import voiceModel, { IVoiceStore } from './VoiceModel';
import { DEFAULT_STATE } from '../constants';

export const VoiceStore = voiceModel.create(DEFAULT_STATE);

const VoiceStoreContext = createContext<IVoiceStore>(VoiceStore);

const useVoiceStore = (): IVoiceStore => {
  return useContext(VoiceStoreContext);
};

export default useVoiceStore;
