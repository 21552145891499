import { Instance, flow, types } from 'mobx-state-tree';
import {
  PHONE_NUMBER_LENGTH,
  ERROR_PHONE,
  INIT_PHONE_VALUE,
  DEFAULT_ERROR,
} from '~/constants/common';
import { calculate, getRandomInt } from '../utils';
import { getCallbackOrder, getPhoneTemplate } from '~/api/api';

const CallbackModel = types
  .model('CallbackModel', {
    phone: (types.string, ''),
    phoneError: (types.string, ''),
    serverError: (types.string, ''),
    isLoading: (types.boolean, false),
    isCompleted: (types.boolean, false),
    isVisibleTimeout: (types.boolean, false),
    isVisible: (types.boolean, false),
    isAgreementChecked: (types.boolean, false),
    isErrorAgreement: (types.boolean, false),
  })
  .actions((self) => {
    return {
      setPhone: (phone) => {
        self.phone = phone;
      },
      setPhoneError: (phoneError) => {
        self.phoneError = phoneError;
      },
      setServerError: (serverError) => {
        self.serverError = serverError;
      },
      setIsLoading: (isLoading) => {
        self.isLoading = isLoading;
      },
      setIsCompleted: (isCompleted) => {
        self.isCompleted = isCompleted;
      },
      setIsVisibleTimeout: (isVisibleTimeout) => {
        self.isVisibleTimeout = isVisibleTimeout;
      },
      setIsVisible: (isVisible) => {
        self.isVisible = isVisible;
      },
      setIsAgreementChecked: (isAgreementChecked) => {
        self.isAgreementChecked = isAgreementChecked;
      },
      setIsErrorAgreement: (isErrorAgreement) => {
        self.isErrorAgreement = isErrorAgreement;
      },
      /** Обработка закрытия виджета в SidePage */
      toggleCallbackWizardVisible: () => {
        self.isVisible = !self.isVisible;
      },
      onCloseClickSidePageCallback: () => {
        self.isVisible = false;
      },
      handleAgreementChecked: (e: React.ChangeEvent<HTMLInputElement>) => {
        self.isAgreementChecked = e.target.checked;
        if (e.target.checked) self.isErrorAgreement = false;
      },
      validateTelephone: () => {
        if (self.phone.length < PHONE_NUMBER_LENGTH) {
          self.phoneError = ERROR_PHONE;
          return true;
        }
        return false;
      },
      getCallback: flow(function* () {
        self.isLoading = true;
        try {
          if (self.phoneError) {
            self.phoneError = '';
          }
          if (self.serverError) self.serverError = '';
          /** галочка соглашения */
          if (!self.isAgreementChecked) {
            self.isErrorAgreement = true;
            self.isLoading = false;
            return;
          }
          /** проверит номер телефона */
          if (self.phone.length < PHONE_NUMBER_LENGTH) {
            self.phoneError = ERROR_PHONE;
            self.isLoading = false;
            return;
          }
          const url = window.location.href;
          const randomNumber = getRandomInt(1, 4503599627370495);
          const template = yield getPhoneTemplate(randomNumber);
          const result = calculate(
            template.phoneTemplateTypeId,
            template.iconBase64,
            template.callTime,
          );
          const xor =
            // eslint-disable-next-line no-bitwise
            BigInt(template.phoneTemplateTypeId) ^ BigInt(randomNumber);
          const res = yield getCallbackOrder(
            self.phone.replace('+7', '8').replace(/[^0-9]/g, ''),
            encodeURIComponent(url),
            xor,
            result,
          );
          if (res.status && res.status === 200) {
            const timeCallback = new Date().getTime();
            localStorage.setItem('timeCallback', String(timeCallback));
            self.isCompleted = true;
            self.isVisibleTimeout = true;
            self.phone = INIT_PHONE_VALUE;
          } else {
            self.serverError = DEFAULT_ERROR;
          }
          self.isLoading = false;
        } catch (e) {
          self.isLoading = false;
          self.serverError = DEFAULT_ERROR;
          throw e;
        }
      }),
    };
  });

export default CallbackModel;
export type ICallbackStore = Instance<typeof CallbackModel>;
