import { flow, Instance, types } from 'mobx-state-tree';
import createApiPathModel, {
  defaultModelState,
} from './models/createApiPathModel';
import {
  ContractMobiles,
  MobileSubscription,
  STORAGE_TYPE,
} from '~/components/Blocks/Templates/Pab2c/Mobile/types';
import { getMobilePhones, getMobileStorageBalances } from '~/api/apiPab2c';
import { toJS } from 'mobx';
import {
  MobileBalancesModel,
  MobilePhoneInfoModel,
} from './models/Pab2cMobileModel';
import { getSortedSubscriptions } from '~/components/Blocks/Templates/Pab2c/Mobile/utils';

const RequestsStateModel = types.model('State', {
  getMobileStorageBalances: createApiPathModel(
    'GET /Mobile/MobileBalance/GetContractMobileBalances',
  ),
  getMobilePhones: createApiPathModel(
    'GET /Mobile/MobilePhoneNumber/GetContractMobiles',
  ),
});

export const Pab2cMobileStore = types
  .model('Pab2cMobileStore', {
    requestsState: RequestsStateModel,
    mobileBalances: types.maybeNull(MobileBalancesModel),
    /** Подключенные ресурсы Мобильный телефонный номер */
    mobilePhonesInfo: types.maybeNull(MobilePhoneInfoModel),
  })
  .views((self) => ({
    get mobilePhonesInfoData() {
      return toJS(self.mobilePhonesInfo);
    },
    get simCardReplacePrice() {
      return self.mobilePhonesInfo?.simCardReplacePrice ?? 0;
    },
    /** Накопители */
    get storages() {
      return self.mobileBalances?.storageMobileBalances
        ? toJS(self.mobileBalances?.storageMobileBalances)
        : [];
    },
    /** Подключенные пакеты */
    get subscriptions() {
      return self.mobileBalances?.itemSubscriptionMobileBalances
        ? toJS(self.mobileBalances?.itemSubscriptionMobileBalances)
        : [];
    },
    get mobilePhones() {
      if (!self.mobilePhonesInfo) return [];
      return toJS(self.mobilePhonesInfo?.contractMobiles);
    },
    /** Перечень номеров телефонов */
    get mobilePhonesNumbers() {
      if (!self.mobilePhonesInfo) return [];
      const numbers: string[] = self.mobilePhonesInfo.contractMobiles.reduce(
        (acc, item) => {
          if (item.msisdn) acc.push(item.msisdn);
          return acc;
        },
        [],
      );
      return numbers;
    },
    get isMobileInfoFetched() {
      return (
        self.requestsState.getMobileStorageBalances.isFetched &&
        self.requestsState.getMobilePhones.isFetched
      );
    },
    get isLoading() {
      return (
        self.requestsState.getMobileStorageBalances.isLoading ||
        self.requestsState.getMobilePhones.isLoading
      );
    },
  }))
  .views((self) => ({
    get numberOfPhones() {
      return self?.mobilePhones?.filter((item) => item?.msisdn)?.length;
    },
    get isConvergentProduct() {
      return self.storages.length !== 0;
    },
    /** Подключенные пакеты роуминга */
    get roamingSubscriptions(): MobileSubscription[] {
      return self.subscriptions.filter((item) =>
        [
          STORAGE_TYPE.ROAMING_INTERNET_ZONE_1,
          STORAGE_TYPE.ROAMING_INTERNET_ZONE_2,
          STORAGE_TYPE.ROAMING_INTERNET_ZONE_3,
          STORAGE_TYPE.ROAMING_INTERNET_ZONE_4,
        ].includes(item.mobileBalanceType),
      );
    },
    get notActivatedSims() {
      const phones: ContractMobiles[] = self.mobilePhones.filter(
        (item) => !item.isActivated,
      );
      return toJS(phones);
    },
    get subscriptionsMinutes() {
      const subscription = self.subscriptions.filter(
        (sub) => sub.mobileBalanceType === STORAGE_TYPE.CALL,
      );
      return getSortedSubscriptions(subscription);
    },
    get subscriptionsGigabites() {
      const subscription = self.subscriptions.filter(
        (sub) => sub.mobileBalanceType === STORAGE_TYPE.INTERNET,
      );
      return getSortedSubscriptions(subscription);
    },
    get subscriptionsRoamingZone1() {
      const subscription = self.subscriptions.filter(
        (sub) => sub.mobileBalanceType === STORAGE_TYPE.ROAMING_INTERNET_ZONE_1,
      );
      return getSortedSubscriptions(subscription);
    },
    get subscriptionsRoamingZone2() {
      const subscription = self.subscriptions.filter(
        (sub) => sub.mobileBalanceType === STORAGE_TYPE.ROAMING_INTERNET_ZONE_2,
      );
      return getSortedSubscriptions(subscription);
    },
    get subscriptionsRoamingZone3() {
      const subscription = self.subscriptions.filter(
        (sub) => sub.mobileBalanceType === STORAGE_TYPE.ROAMING_INTERNET_ZONE_3,
      );
      return getSortedSubscriptions(subscription);
    },
    get subscriptionsRoamingZone4() {
      const subscription = self.subscriptions.filter(
        (sub) => sub.mobileBalanceType === STORAGE_TYPE.ROAMING_INTERNET_ZONE_4,
      );
      return getSortedSubscriptions(subscription);
    },
    getStorageByType(type: STORAGE_TYPE) {
      return self.storages.find((store) => store.mobileBalanceType === type);
    },
  }))
  .views((self) => ({
    get connectedRoamingTypes() {
      const roamingTypes = new Set(
        self.roamingSubscriptions.map((item) => item.mobileBalanceType),
      );
      return [...roamingTypes];
    },
    get subscriptionsMap() {
      return {
        [STORAGE_TYPE.SMS]: [],
        [STORAGE_TYPE.CALL]: self.subscriptionsMinutes,
        [STORAGE_TYPE.INTERNET]: self.subscriptionsGigabites,
        [STORAGE_TYPE.ROAMING_INTERNET_ZONE_1]: self.subscriptionsRoamingZone1,
        [STORAGE_TYPE.ROAMING_INTERNET_ZONE_2]: self.subscriptionsRoamingZone2,
        [STORAGE_TYPE.ROAMING_INTERNET_ZONE_3]: self.subscriptionsRoamingZone3,
        [STORAGE_TYPE.ROAMING_INTERNET_ZONE_4]: self.subscriptionsRoamingZone4,
      };
    },
  }))
  .views((self) => ({
    getMobilePhone(msisdn: string): ContractMobiles {
      return self.mobilePhones?.find((item) => item.msisdn === msisdn);
    },
    getUnspentUnits(type: STORAGE_TYPE) {
      const subscriptions = self.subscriptionsMap[type] ?? [];
      return subscriptions.reduce((acc, sub) => {
        const sum = acc + sub.currentQuantity;
        return sum;
      }, 0);
    },
    /** Получаем максимальное значение шкалы в пакетах */
    getMaxUnitsSub(type: STORAGE_TYPE) {
      const subscriptions = self.subscriptionsMap[type] ?? [];
      return subscriptions.reduce((acc, sub) => {
        const sum = acc + sub.startQuantity;
        return sum;
      }, 0);
    },
    /** Получаем максимальное значение шкалы в накопителе */
    getMaxUnitsStorage(type: STORAGE_TYPE) {
      if (type === STORAGE_TYPE.SMS)
        return self.storages.find(
          (store) => store.mobileBalanceType === STORAGE_TYPE.SMS,
        )?.maxQuantity;

      const initialValue =
        self.storages.find((store) => store.mobileBalanceType === type)
          ?.maxQuantity || 0;

      const subscriptions = self.subscriptionsMap[type] ?? [];
      return subscriptions.reduce((acc, sub) => {
        const sum = acc + sub.currentQuantity;
        return sum;
      }, initialValue);
    },
  }))
  .actions((self) => ({
    /** Получает информацию о накопителях и подключенных пакетах */
    getMobileStorageBalances: flow(function* () {
      self.requestsState.getMobileStorageBalances.reset();
      self.requestsState.getMobileStorageBalances.setLoading();
      try {
        const res = yield getMobileStorageBalances();
        /** Округляем до сотых currentQuantity */
        res.itemSubscriptionMobileBalances.forEach((subscription) => {
          subscription.currentQuantity =
            Math.round(subscription.currentQuantity * 100) / 100;
        });
        self.mobileBalances = res;
        self.requestsState.getMobileStorageBalances.setSuccess();
      } catch (e) {
        self.requestsState.getMobileStorageBalances.setFail();
        throw e;
      }
    }),
    /** Получает информацию о подключенных мобильных номерах */
    getMobilePhones: flow(function* () {
      self.requestsState.getMobilePhones.reset();
      self.requestsState.getMobilePhones.setLoading();
      try {
        const res = yield getMobilePhones();
        self.mobilePhonesInfo = res;
        self.requestsState.getMobilePhones.setSuccess();
      } catch (e) {
        self.requestsState.getMobilePhones.setFail();
        throw e;
      }
    }),
    setNewPhoneName: (msisdn: string, name: string) => {
      self.mobilePhonesInfo.contractMobiles.find(
        (info) => info.msisdn === msisdn,
      ).subscriberName = name;
    },
    setIsInternationalRoamingEnabled: (msisdn: string, isEnabled: boolean) => {
      self.mobilePhonesInfo.contractMobiles.find(
        (info) => info.msisdn === msisdn,
      ).isInternationalRoamingEnabled = isEnabled;
    },
    resetMobileStorage: () => {
      self.requestsState.getMobileStorageBalances.reset();
      self.mobileBalances = null;
      self.mobilePhonesInfo = null;
    },
  }))
  .actions((self) => ({
    getMobileInfo: flow(function* () {
      yield Promise.allSettled([
        self.getMobileStorageBalances(),
        self.getMobilePhones(),
      ]);
    }),
  }));

export type IPab2cMobileStore = Instance<typeof Pab2cMobileStore>;

export const Pab2cMobileStoreInstance = Pab2cMobileStore.create({
  requestsState: {
    getMobileStorageBalances: defaultModelState,
    getMobilePhones: defaultModelState,
  },
  mobileBalances: null,
  mobilePhonesInfo: null,
});
