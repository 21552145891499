/** libraries */
import { createContext, useContext } from 'react';
/** components */
import { defaultModelState } from '~/stores/models/createApiPathModel';
import CreatePasswordModel, {
  ICreatePasswordModel,
} from './CreatePasswordModel';
/** constants */
import {
  initialPhoneNumber,
  SP_STATE,
} from '~/components/Blocks/Templates/Pab2c/Settings/constants';
import { DEFAULT_RESULT } from '~/constants/common';

export const CreatePasswordStore = CreatePasswordModel.create({
  requestsState: {
    sendingCode: defaultModelState,
    sendingCodeByCall: defaultModelState,
    confirmCode: defaultModelState,
    defineNewPassword: defaultModelState,
  },
  isShowCreatePasswordWizard: false,
  newPassword: '',
  newPasswordError: '',
  newPasswordConfirmation: '',
  newPasswordConfirmationError: '',
  phoneNumber: initialPhoneNumber,
  phoneNumberError: '',
  contractNumber: '',
  contractNumberError: '',
  remains: null,
  code: '',
  codeError: '',
  state: SP_STATE.PHONE,
  isBlockedContract: false,
  blockedContractTime: null,
  token: null,
  result: DEFAULT_RESULT,
});

const StoreContext = createContext<ICreatePasswordModel>(CreatePasswordStore);

const useCreatePasswordStore = (): ICreatePasswordModel => {
  return useContext(StoreContext);
};

export default useCreatePasswordStore;
