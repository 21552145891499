/** libraries */
import { flow, types } from 'mobx-state-tree';
/** constants */
import { DEFAULT_ERROR } from '~/components/Blocks/Templates/Pab2c/Settings/constants';
import { PASSWORD_ERRORS } from '../SidePages/ChangePassword/constants';
import { DEFAULT_RESULT, PASSWORD_MAX_LENGTH } from '~/constants/common';
import { SIDE_PAGES } from '~/components/AuthWizard/constants';
/** api */
import { changePassword } from '~/api/apiPab2c';
/** utils */
import createApiPathModel, {
  defaultModelState,
} from '~/stores/models/createApiPathModel';
/** interfaces */
import { ResultPropsModel } from './models';

const RequestsStateModel = types.model('State', {
  changePassword: createApiPathModel(
    'GET /Security/PasswordRecovery/ChangePassword',
  ),
});

export const ChangePasswordModel = types
  .model('ChangePassword', {
    requestsState: RequestsStateModel,
    isShowChangePasswordWizard: types.boolean,
    /** Текущий пароль */
    currentPassword: types.string,
    /** Ошибка текущего пароля */
    currentPasswordError: types.string,
    /** Новый пароль */
    newPassword: types.string,
    /** Подтверждение нового пароля */
    newPasswordConfirmation: types.string,
    /** Результат изменения пароля */
    result: ResultPropsModel,
  })
  .views((self) => ({
    /** Проверка на условия отключения кнопки смены пароля */
    get isDisableButton() {
      const re = /(?=^.{8,}$)(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?!.*\s).*$/;
      const symbols = /[!@#$%^&*()_\-+=[{\]};:<>|.\/?]/;
      return (
        self.newPassword !== self.newPasswordConfirmation ||
        !re.test(self.newPassword) ||
        symbols.test(self.newPassword) ||
        self.newPassword.length > PASSWORD_MAX_LENGTH
      );
    },
  }))
  .actions((self) => ({
    setIsShowChangePasswordWizard: (isShow: boolean) => {
      self.isShowChangePasswordWizard = isShow;
    },
    setCurrentPassword: (password: string) => {
      self.currentPassword = password;
    },
    setCurrentPasswordError: (error: string) => {
      self.currentPasswordError = error;
    },
    setNewPassword: (password: string) => {
      self.newPassword = password;
    },
    setNewPasswordConfirmation: (password: string) => {
      self.newPasswordConfirmation = password;
    },
    changePassword: flow(function* (setOpenSPAfterAuthorizationState) {
      self.requestsState.changePassword.reset();
      self.requestsState.changePassword.setLoading();
      try {
        yield changePassword(self.currentPassword, self.newPassword);
        self.result = { isResult: true, isCorrect: true };
        self.requestsState.changePassword.setSuccess();
        setOpenSPAfterAuthorizationState(null);
      } catch (e) {
        if (e.statusCode === 401) {
          setOpenSPAfterAuthorizationState(SIDE_PAGES.CHANGE_PASSWORD_ACTION);
          return;
        }
        setOpenSPAfterAuthorizationState(null);
        self.requestsState.changePassword.setFail();
        const error = JSON.parse(e.errorMessage);
        switch (error?.Type) {
          case PASSWORD_ERRORS.AUTH_COUNT:
            self.currentPasswordError = `Неправильный пароль. По истечении попыток ввода пароля доступ к договору будет временно ограничен. Осталось попыток: ${error?.Extensions?.Remains}`;
            break;
          case PASSWORD_ERRORS.BLOCK:
            self.currentPasswordError = `Доступ к договору ${error?.Extensions?.ContractName} заблокирован на ${error?.Extensions?.BlockMinutes} минут`;
            break;
          case PASSWORD_ERRORS.CONTRACT_NOT_SERVICED:
            self.currentPasswordError = 'Договор не обслуживается';
            break;
          default:
            self.currentPasswordError = DEFAULT_ERROR;
        }
        throw e;
      }
    }),
    resetChangePasswordState: () => {
      self.isShowChangePasswordWizard = false;
      self.result = DEFAULT_RESULT;
      self.currentPassword = '';
      self.newPassword = '';
      self.newPasswordConfirmation = '';
    },
  }));

export const ChangePasswordStoreInstance = ChangePasswordModel.create({
  requestsState: {
    changePassword: defaultModelState,
  },
  isShowChangePasswordWizard: false,
  currentPassword: '',
  currentPasswordError: '',
  newPassword: '',
  newPasswordConfirmation: '',
  result: DEFAULT_RESULT,
});
