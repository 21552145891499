import { Instance, types } from 'mobx-state-tree';
import { PromoPriceInfoProps } from '~/components/Blocks/Shared/Shared.types';
import {
  CallToActionDeviceInfo,
  DataSelectPurchaseTypesProps,
  PurchaseTypes,
} from '../../CallToAction.types';
import { getPromoPrice } from '~/components/Blocks/Shared/Shared.utils';
import { SelectPurchaseTypesModel } from '../models/CallToActionsModel';

const CallToActionStateModel = types
  .model('CallToActionState', {
    /* Флаг отображения настроек продукта */
    showSettings: (types.boolean, false),
    /* Флаг активации сервиса "Управление просмотром */
    enabledTimeShift: (types.boolean, false),
    /* Флаг активации сервиса "Wi-Fi роутер" */
    enabledRouter: (types.boolean, false),
    /* Флаг активации сервиса "Пульт" */
    enabledRemote: (types.boolean, false),
    /* Флаг активации сервиса "Телеприставка" */
    enabledSTB: (types.boolean, false),
    /* Флаг отображения виджета */
    isOpenConnectionWizard: (types.boolean, false),
    // Абонентская плата в день
    feePerDay: types.maybeNull(types.number),
    /* Стоимость сервиса "Пульт" */
    purchaseRemotePrice: types.maybeNull(types.number),
    /* Вариант приобретения роутера */
    selectRouterTypes: types.maybeNull(SelectPurchaseTypesModel),
    /* Вариант приобретения приставки */
    selectSTBTypes: types.maybeNull(SelectPurchaseTypesModel),
    /* Флаг переноса мобильного номера */
    isTransferMobileNumber: (types.boolean, false),
  })
  .actions((self) => ({
    setShowSettings: (showSettings) => {
      self.showSettings = showSettings;
    },
    setEnabledTimeShift: (enabledTimeShift) => {
      self.enabledTimeShift = enabledTimeShift;
    },
    setIsTransferMobileNumber: (isTransfer: boolean) => {
      self.isTransferMobileNumber = isTransfer;
    },
    setEnabledRouter: (enabledRouter) => {
      self.enabledRouter = enabledRouter;
    },
    setEnabledRemote: (enabledRemote) => {
      self.enabledRemote = enabledRemote;
    },
    setEnabledSTB: (enabledSTB) => {
      self.enabledSTB = enabledSTB;
    },
    setIsOpenConnectionWizard: (isOpenConnectionWizard) => {
      self.isOpenConnectionWizard = isOpenConnectionWizard;
    },
    setFeePerDay: (feePerDay) => {
      self.feePerDay = feePerDay;
    },
    setPurchaseRemotePrice: (purchaseRemotePrice) => {
      self.purchaseRemotePrice = purchaseRemotePrice;
    },
    setSelectRouterTypes: (selectRouterTypes) => {
      self.selectRouterTypes = selectRouterTypes;
    },
    setSelectSTBTypes: (selectSTBTypes) => {
      self.selectSTBTypes = selectSTBTypes;
    },
    /* Обработка нажатия на кнопку "Подключить" */
    openWizard: () => {
      self.isOpenConnectionWizard = true;
    },
    /* Вычисляет бесплатность оборудования на продукте */
    isFreeDevice: (
      devicePriceInfo: PromoPriceInfoProps,
      deviceOwnershipPriceOn: number,
    ): boolean => {
      // Флаг бесплатности сервиса
      const isFree = devicePriceInfo.promoPrice === 0;
      // Флаг возможности приобретения оборудования только через покупку
      const buyOnly = !devicePriceInfo.promoAnnuity && !deviceOwnershipPriceOn;
      // Флаг бесплатного пользования
      const isFreeOwnership =
        !isFree && !buyOnly && deviceOwnershipPriceOn === 0;
      return isFree || isFreeOwnership;
    },
    /* Формирует сумму для устройств в составе абонентской платы исходя из выбранных настроек */
    getDeviceFee: (
      enabled: boolean,
      info: CallToActionDeviceInfo,
      selectTypes: DataSelectPurchaseTypesProps,
    ) => {
      if (
        !enabled ||
        getPromoPrice(info.promoPriceInfos, { currentPrice: info.price })
          .promoPrice === 0
      )
        return 0;
      if (selectTypes.label === PurchaseTypes.ANNUITY) return info.annuity;
      if (selectTypes.label === PurchaseTypes.OWNERSHIP)
        return info.ownershipPriceOn;
      return 0;
    },
    /* Оставляет только доступные варианты приобретения оборудования */
    filterPurchaseTypes: (
      purchaseTypes: DataSelectPurchaseTypesProps[],
      annuityPrice: number,
      ownershipPrice: number,
    ): DataSelectPurchaseTypesProps[] => {
      const result = purchaseTypes.filter((item) => {
        if (annuityPrice === null && ownershipPrice === null)
          return item.label === PurchaseTypes.PURCHASE;
        if (annuityPrice === null) return item.label !== PurchaseTypes.ANNUITY;
        if (ownershipPrice === null)
          return item.label !== PurchaseTypes.OWNERSHIP;
        return item;
      });
      return result;
    },
  }));
export default CallToActionStateModel;
export type ICallToActionStateStore = Instance<typeof CallToActionStateModel>;
